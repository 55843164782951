import React, { Fragment } from "react";
import { color } from "./stylesheet";
import { link } from "./paths";
import { NavLink as RouterNavLink } from "react-router-dom";
import { Project } from "./entity-types";
import { RootSideNav } from "./root-nav";
import { sortByStatus } from "./sorts";
import { useHover } from "./custom-hooks";
import { useViewportSize } from "./viewport-observer";
import BackButton from "./back-button";
import Label from "./label";
import NavLink from "./nav-link";
import ProjectPicture from "./project-picture";
import SideNav from "./side-nav";
import SwitcherSideNav from "./switcher-side-nav";
import TieredTopNav from "./tiered-top-nav";

function ProjectSwitcherLink(props: Project) {
  const { hovered, ...handlers } = useHover();

  return (
    <RouterNavLink
      {...handlers}
      to={link.toProjectRoot({ projectId: props.id })}
      style={{
        display: "flex",
        alignItems: "center",
        padding: "0.5rem",
        color:
          props.status === "archived"
            ? color.LIGHT_BLUE_GRAY
            : color.DARK_STEEL,
        transform: hovered ? "translateX(-0.25rem)" : "translateX(0)",
        transition: "transform 0.2s",
      }}
      activeStyle={{
        color: color.BLUE,
      }}>
      <div style={{ opacity: props.status === "archived" ? 0.6 : 1}}>
        <ProjectPicture pictureUrl={props.thumbUrl} />

      </div>
      <div
        style={{
          fontSize: "1.25rem",
          lineHeight: "1.25rem",
        }}>
        {props.name}
      </div>
    </RouterNavLink>
  );
}

interface ProjectNavLinksProps extends Project {
  condensed?: boolean;
}

function ProjectNavLinks(props: ProjectNavLinksProps) {
  return (
    <Fragment>
      <NavLink
        exact
        to={link.toProjectRoot({ projectId: props.id })}
        icon="home"
        label="Home"
        condensed={props.condensed}
      />
      <NavLink
        to={link.toProjectStoryboards({ projectId: props.id })}
        icon="bar-chart"
        label="Storyboards"
        condensed={props.condensed}
      />
      <NavLink
        to={link.toProjectDataSources({ projectId: props.id })}
        icon="git-merge"
        label="Data Sources"
        condensed={props.condensed}
      />
      <NavLink
        to={link.toProjectCollaborators({ projectId: props.id })}
        icon="collaborator"
        label="Collaborators"
        condensed={props.condensed}
      />
      <NavLink
        to={link.toProjectSettings({ projectId: props.id })}
        icon="cog"
        label="Settings"
        condensed={props.condensed}
      />
    </Fragment>
  );
}

interface ProjectSideNavProps extends Project {
  projects: Project[];
}

export function ProjectSideNav(props: ProjectSideNavProps) {
  const viewport = useViewportSize();

  if (viewport.isExtraWidePlus) {
    return (
      <Fragment>
        <RootSideNav condensed />
        <SwitcherSideNav
          navTitle={
            <Fragment>
              <ProjectPicture pictureUrl={props.thumbUrl} />
              <div style={{ fontSize: "1.25rem", lineHeight: "1.25rem" }}>
                {props.name}
              </div>
            </Fragment>
          }
          navLinks={
            <Fragment>
              {props.status === "archived" && (
                <div
                  style={{
                    padding: "1rem",
                    margin: "0 1rem 1.5rem",
                    fontSize: "1.25rem",
                    lineHeight: "1.5rem",
                    borderRadius: "0.25rem",
                    color: "white",
                    backgroundColor: color.BLUE_GRAY,
                  }}>
                  Archived project
                </div>
              )}
              <ProjectNavLinks {...props} />
            </Fragment>
          }
          renderSwitcherTitle={() => <Label>Projects</Label>}
          renderSwitcherLinks={() =>
            props.projects
              .sort(sortByStatus)
              .map(project => (
                <ProjectSwitcherLink {...project} key={project.id} />
              ))
          }
        />
      </Fragment>
    );
  }

  return (
    <SideNav narrow>
      <div
        style={{
          padding: "0.75rem 1rem",
          marginBottom: "2.5rem",
        }}>
        <BackButton fallbackTo={link.toRootProjects()} />
      </div>
      <ProjectNavLinks {...props} condensed />
    </SideNav>
  );
}

export function ProjectTopNav(props: Project) {
  return (
    <TieredTopNav>
      <Fragment>
        <BackButton fallbackTo={link.toRootProjects()} marginRight="small" />
        <ProjectPicture size="small" pictureUrl={props.thumbUrl} />
        <Label>{props.name}</Label>
      </Fragment>
      <ProjectNavLinks {...props} condensed />
    </TieredTopNav>
  );
}
