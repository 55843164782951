import React from "react";
import { SaveButton } from "./button";
import ActionsArea from "./actions-area";
import ContentSection from "./content-section";
import ContentView from "./content-view";
import TextInput from "./text-input";

export default function RootUserView() {
    const props = {
      name: "Mary Reese",
      email: "mary@ph.com",
      phone: "+123 123 123",
    }

    return (
      <ContentView title={`Account Settings`}>
        <ContentSection title="General">
          <TextInput label="Name" defaultValue={props.name} />
          <TextInput label="Email" type="email" defaultValue={props.email} />
          <TextInput label="Phone" type="tel" defaultValue={props.phone} />
          <ActionsArea>
            <SaveButton disabled />
          </ActionsArea>
        </ContentSection>
      </ContentView>
    );
}
