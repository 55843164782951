import React from "react";
import { color } from "./stylesheet";

interface CheckboxProps {
  checked: boolean;
}

function Checkbox(props: CheckboxProps) {
  return (
    <div
      style={{
        width: "1.5rem",
        height: "1.5rem",
        fontSize: "1rem",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: props.checked ? color.BLUE : "white",
        animation: props.checked ? "0.2s ease-out panoramic-pop" : "none",
        borderRadius: "50%",
        boxShadow: props.checked
          ? "0 1px 2px -1px rgba(0,0,0,0.1)"
          : "inset 0 0 0 2px rgba(0,0,0,0.1)",
      }}>
      {props.checked && (
        <svg width="1em" height="1em" viewBox="0 0 12 12">
          <path
            d="M4.524 7.303l5.01-5.01a1 1 0 1 1 1.414 1.414l-6.424 6.424-3.231-3.23a1 1 0 0 1 1.414-1.415l1.817 1.817z"
            fill="white"
            fillRule="nonzero"
          />
        </svg>
      )}
    </div>
  );
}

interface StoryboardTemplateProps {
  id: string;
  name: string;
  description: string;
  color: string;
  onClick: () => void;
  selected: boolean;
}

export default function StoryboardTemplateCard(props: StoryboardTemplateProps) {
  // Had some alignment issue with buttons and grid... :( Using a div is a temporary
  // hack and should not be used in production facing code unless all button functionality
  // has been replicate in the synthetic button-like div.
  return (
    <div
      tabIndex={0}
      onClick={props.onClick}
      style={{
        display: "block",
        borderRadius: "0.25rem",
        padding: "1.5rem",
        cursor: "pointer",
        backgroundColor: props.selected ? color.VERY_LIGHT_GRAY : "white",
        boxShadow: props.selected
          ? "0 0 3px -1px rgba(0,0,0,0.40)"
          : "0 0 3px -1px rgba(0,0,0,0.30), 0 4px 6px -2px rgba(0,0,0,0.10)",
        transition: "box-shadow 0.1s",
      }}>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          height: "1rem",
          marginBottom: "0.75rem",
        }}>
        <div
          style={{
            opacity: props.selected ? 0.2 : 1,
            height: "1rem",
            width: "4rem",
            borderRadius: "0.5rem",
            background: props.color,
          }}
        />
        <Checkbox checked={props.selected} />
      </div>
      <div
        style={{
          fontSize: "1.33333rem",
          lineHeight: "1.75rem",
          marginBottom: "0.5rem",
          color: props.selected ? color.LIGHT_BLUE_GRAY : color.ALMOST_BLACK,
        }}>
        {props.name}
      </div>
      <div
        style={{
          fontSize: "1.25rem",
          lineHeight: "1.5rem",
          color: props.selected ? color.LIGHT_BLUE_GRAY : color.BLUE_GRAY,
        }}>
        {props.description}
      </div>
    </div>
  );
}