import React from "react";
import { Button } from "./button";
import { Company } from "./entity-types";
import { useDialogs } from "./dialog-manager";
import AddUsersDialog from "./add-users-dialog";
import BlankSlate from "./blank-slate";
import ListView from "./list-view";
import ResponsiveButton from "./responsive-button";
import ResponsiveListItem from "./responsive-list-item";
import UserPicture from "./user-picture";

export default function CompanyUsersView(props: Company) {
  const dialogs = useDialogs();

  function openDialog() {
    dialogs.openDialog(<AddUsersDialog />);
  }

  return (
    <ListView
      title="Users"
      items={props.users}
      actions={<ResponsiveButton onClick={openDialog} label="Add Users" icon="add" />}
      renderItem={user => (
        <ResponsiveListItem
          key={user.id}
          to="/not-implemented"
          icon={<UserPicture pictureUrl={user.avatarUrl} />}
          title={user.name}
          text={user.email}
        />
      )}
      renderBlankSlate={() => (
        <BlankSlate
          illustration="users"
          title="No Users"
          instructions="Add users to this company to give them access."
          actions={<Button onClick={openDialog} label="Add Users" theme="blue" />}
        />
      )}
    />
  );
}
