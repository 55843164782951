import * as types from "./entity-types";
import id from "shortid";

// Factories

interface CompanyInput extends Partial<types.Company> {
  name: string;
}

export function createCompany(payload: CompanyInput): types.Company {
  return {
    id: payload.id || id.generate(),
    name: payload.name,
    projects: payload.projects || [],
    teams: payload.teams || [],
    users: payload.users || [],
  };
}

interface UserInput extends Partial<types.User> {
  name: string;
  email: string;
}

export function createUser(payload: UserInput): types.User {
  return {
    id: payload.id || id.generate(),
    name: payload.name,
    email: payload.email,
    avatarUrl: payload.avatarUrl || "",
  };
}

interface ProjectInput extends Partial<types.Project> {
  name: string;
}

export function createProject(payload: ProjectInput): types.Project {
  return {
    id: payload.id || id.generate(),
    name: payload.name,
    thumbUrl: payload.thumbUrl || "",
    status: payload.status || "active",
    conversations: payload.conversations || [createOnboardingConversation()],
    dataSources: payload.dataSources || [],
    collaborators: payload.collaborators || [],
    storyboards: payload.storyboards || [],
  };
}

function getDefaultConnections(): types.Storyboard["connections"] {
  return [
    { provider: "FACEBOOK_ADS", required: false },
    { provider: "FACEBOOK_PAGE_INSIGHTS", required: false },
    { provider: "GOOGLE_ADS", required: false },
    { provider: "SNAPCHAT", required: false },
    { provider: "TWITTER", required: false },
    { provider: "APP_NEXUS", required: false },
  ];
}

interface StoryboardInput extends Partial<types.Storyboard> {
  name: string;
  description: string;
  project: types.Project;
}

export function createStoryboard(payload: StoryboardInput): types.Storyboard {
  const storyboard = {
    id: payload.id || id.generate(),
    name: payload.name,
    description: payload.description,
    color: payload.color || "#f2f2f2",
    starred: !!payload.starred,
    project: payload.project,
    connections: payload.connections || getDefaultConnections(),
  };

  payload.project.storyboards.push(storyboard);

  return storyboard;
}

interface StoryboardTemplateInput extends Partial<types.StoryboardTemplate> {
  name: string;
  description: string;
  category: types.StoryboardTemplate["category"];
}

export function createStoryboardTemplate(
  payload: StoryboardTemplateInput,
): types.StoryboardTemplate {
  return {
    id: payload.id || id.generate(),
    name: payload.name,
    description: payload.description,
    color: payload.color || "#f2f2f2",
    category: payload.category,
    connections: payload.connections || getDefaultConnections(),
  };
}

interface DataSourceInput extends Partial<types.DataSource> {
  name: string;
  provider: types.DataSourceProvider;
}

export function createDataSource(payload: DataSourceInput): types.DataSource {
  return {
    id: payload.id || id.generate(),
    status: payload.status || "initializing",
    name: payload.name,
    provider: payload.provider,
    token: payload.token || id.generate().repeat(3),
  };
}

interface TeamInput extends Partial<types.Team> {
  name: string;
}

export function createTeam(payload: TeamInput): types.Team {
  return {
    id: payload.id || id.generate(),
    name: payload.name,
    members: payload.members || [],
  };
}

interface ConversationInput extends Partial<types.Conversation> {
  name: string;
}

export function createConversation(
  payload: ConversationInput,
): types.Conversation {
  return {
    id: payload.id || id.generate(),
    thumbUrl: payload.thumbUrl,
    name: payload.name,
    status: payload.status || "active",
    created: payload.created || Date.now(),
    comments: payload.comments || [],
  };
}

interface CommentInput extends Partial<types.Comment> {
  text: string;
  author: types.User;
}

export function createComment(payload: CommentInput): types.Comment {
  return {
    id: payload.id || id.generate(),
    created: payload.created || Date.now(),
    text: payload.text,
    action: payload.action,
    author: payload.author,
  };
}

interface OnboardingInput {
  created?: number;
}

export function createFirstOnboardingComment(payload: OnboardingInput = {}) {
  return createComment({
    text: "Hey there! Welcome to your new project. The best way to get started is to connect some data sources. (What’s the point of a project without data, right?)",
    action: "CONNECT_DATA_SOURCES",
    created: payload.created,
    author: Joi,
  });
}

export function createSecondOnboardingComment(payload: OnboardingInput = {}) {
  return createComment({
    text: "Great! 🎉 I see you connected first data sources. It will take me some time to sync and analyize the data. In the meantime, you can continue by picking up some storyboards for this project.",
    action: "ADD_STORYBOARDS",
    created: payload.created,
    author: Joi,
  });
}

export function createThirdOnboardingComment(payload: OnboardingInput = {}) {
  return createComment({
    text: "Wonderful. Your storyboards have been added — remember to star the ones you like! ⭐️ This concludes your project setup. Feel free to resolve this conversation with the checkmark in the top right corner of this card.",
    created: payload.created,
    author: Joi,
  });
}

function createOnboardingConversation() {
  return createConversation({
    name: "Getting started",
    thumbUrl: "/images/getting-started.png",
    comments: [createFirstOnboardingComment()],
  });
}

// Users

const Joi = createUser({
  name: "Joi",
  email: "joi@panoramic.com",
  avatarUrl: "/images/joi.png",
});

const MaryReese = createUser({
  name: "Mary Reese",
  email: "mary.reese@pheonixmovies.com",
  avatarUrl: "/images/mary-reese.png",
});

const TereseHunter = createUser({
  name: "Terese Hunter",
  email: "terese.hunter@pheonixmovies.com",
  avatarUrl: "/images/terese-hunter.png",
});

const JohnBlack = createUser({
  name: "John Black",
  email: "john.black@pheonixmovies.com",
  avatarUrl: "/images/john-black.png",
});

// Projects

const PurpleNights = createProject({
  id: "MuTQE8M6p",
  name: "Purple Nights",
  thumbUrl: "/images/purple-nights.png",
  collaborators: [MaryReese, TereseHunter, JohnBlack],
  dataSources: [
    createDataSource({
      status: "intaking",
      provider: "FACEBOOK_ADS",
      name: "Purple Nights (Official UK)",
    }),
    createDataSource({
      status: "intaking",
      provider: "FACEBOOK_ADS",
      name: "Purple Nights (Official US)",
    }),
    createDataSource({
      status: "intaking",
      provider: "FACEBOOK_ADS",
      name: "Noches Purpuras (Espana)",
    }),
  ],
  conversations: [
    createConversation({
      name: "Getting started",
      thumbUrl: "/images/getting-started.png",
      status: "resolved",
      created: 1545594435698,
      comments: [
        createFirstOnboardingComment({ created: 1545594435698 }),
        createSecondOnboardingComment({ created: 1545599435698 }),
        createThirdOnboardingComment({ created: 1545599435698 }),
      ],
    }),
    createConversation({
      name: "Weekly Spend Overview",
      thumbUrl: "/images/bar-chart.png",
      created: 1545594435698,
      comments: [
        createComment({
          created: 1545594435698,
          text: "Hey @Terese Hunter, are you OK with this spend?",
          author: MaryReese,
        }),
        createComment({
          created: 1545594435698,
          text: "Yep, all good!",
          author: TereseHunter,
        }),
      ],
    }),
    createConversation({
      name: "Impressions",
      thumbUrl: "/images/scatter-plot.png",
      created: 1545594435698,
      comments: [
        createComment({
          created: 1545594435698,
          text: "@Terese Hunter, @John Black, @Constanza Magalhães: Are you ok with the spend this week. It has been a little higher than originally planned, but the engagement was so good we decided to double down.",
          author: MaryReese,
        }),
        createComment({
          created: 1545594435698,
          text: "In general this makes sense to me, but it would be great if we could possibly get more of a heads-up next time.",
          author: TereseHunter,
        }),
        createComment({
          created: 1545594435698,
          text: "What Terese said…",
          author: JohnBlack,
        }),
        createComment({
          created: 1545594435698,
          text: "Got it. Will do.",
          author: MaryReese,
        }),
      ],
    }),
  ],
});

const Emilly = createProject({
  id: "ZbhVK52FM",
  name: "Emilly",
  thumbUrl: "/images/emilly.png",
});

const Koda3 = createProject({
  id: "uRatU4kaz",
  name: "Koda 3",
  thumbUrl: "/images/koda-3.png",
});

const GoldenLife = createProject({
  id: "zJhdeHWAQO",
  name: "Golden Life",
  thumbUrl: "/images/golden-life.png",
  status: "archived",
});

const HolyMeadow = createProject({
  id: "m8oxjoo7d",
  name: "Holy Meadow",
  thumbUrl: "/images/holy-meadow.png",
});

const IndianSummer = createProject({
  id: "E3cDo2DxK",
  name: "Indian Summer",
  thumbUrl: "/images/indian-summer.png",
});

const LandOfTheTechnoHorses = createProject({
  id: "ToGPpmRcC",
  name: "Land of the Techno Horses",
  thumbUrl: "/images/land-of-the-techno-horses.png",
});

const Lean = createProject({
  id: "CV120hXW0",
  name: "Lean",
  thumbUrl: "/images/lean.png",
});

const MonsterLooks = createProject({
  id: "KwufXHdzo",
  name: "Monster Looks",
  thumbUrl: "/images/monster-looks.png",
  status: "archived",
});

// Storyboards

const PurpleNightsMediaOverview = createStoryboard({
  id: "82TrqgDbMe",
  name: "Media Overview",
  description: "An overview of your media spend",
  color: "#FFEB3B",
  starred: true,
  project: PurpleNights,
});

const PurpleNightsAudienceOverview = createStoryboard({
  id: "3jLrhG2vJ",
  name: "Audience Overview",
  description: "An overview of your audience reactions",
  color: "#78919C",
  project: PurpleNights,
});

const PurpleNightsFacebookPerformance = createStoryboard({
  id: "mFH8ZjZafY",
  name: "Facebook Performance",
  description: "Deep dive into your Facebook performance",
  color: "#3C5A99",
  starred: true,
  project: PurpleNights,
  connections: [
    {
      provider: "FACEBOOK_ADS",
      required: true,
    },
  ],
});

const PurpleNightsTwitterPerformance = createStoryboard({
  id: "nSdFI32Ft",
  name: "Twitter Performance",
  description: "Deep dive into your Twitter performance",
  color: "#50C2F7",
  project: PurpleNights,
  connections: [
    {
      provider: "TWITTER",
      required: true,
    },
  ],
});

const PurpleNightsSnapshatPerformance = createStoryboard({
  id: "08WPzdwKT",
  name: "Snapchat Performance",
  description: "Deep dive into your Snapchat performance",
  color: "#FFFC00",
  project: PurpleNights,
  connections: [
    {
      provider: "SNAPCHAT",
      required: true,
    },
  ],
});

const PurpleNightsInstagramPerformance = createStoryboard({
  id: "gTlT_DjOM3",
  name: "Instagram Performance",
  description: "Deep dive into your Instagram performance",
  color: "linear-gradient(-90deg, #4863CA 0%, #AB47BC 23%, #FF543F 66%, #FFCD52 100%)",
  starred: true,
  project: PurpleNights,
  connections: [
    {
      provider: "FACEBOOK_ADS",
      required: true,
    },
  ],
});

const PurpleNightsAppNexusPerformance = createStoryboard({
  id: "fuUcHrCEy",
  name: "AppNexus Performance",
  description: "Deep dive into your AppNexus performance",
  color: "#f78200",
  project: PurpleNights,
  connections: [
    {
      provider: "APP_NEXUS",
      required: true,
    },
  ],
});

// Storyboard Templates

// Teams

const CreativeTeam = createTeam({
  name: "Creative Team",
  members: [MaryReese, JohnBlack],
});

const AccountTeam = createTeam({
  name: "Account Team",
  members: [MaryReese],
});

const ManagementTeam = createTeam({
  name: "Management Team",
  members: [TereseHunter],
});

// Companies

const PhoenixEntertainment = createCompany({
  id: "82OwyNZvl",
  name: "Phoenix Entertainment",
  projects: [PurpleNights, Emilly, Koda3, GoldenLife, HolyMeadow],
  users: [JohnBlack, MaryReese],
  teams: [CreativeTeam, AccountTeam, ManagementTeam],
});

const BoltAuto = createCompany({
  id: "260wyNZvc",
  name: "Bolt Auto",
});

// Data Tree

export interface Data {
  companies: types.Company[];
  projects: types.Project[];
  storyboards: types.Storyboard[];
  providers: types.DataSourceProvider[];
  storyboardTemplates: types.StoryboardTemplate[];
}

const data: Data = {
  storyboards: [
    PurpleNightsMediaOverview,
    PurpleNightsAudienceOverview,
    PurpleNightsFacebookPerformance,
    PurpleNightsInstagramPerformance,
    PurpleNightsTwitterPerformance,
    PurpleNightsSnapshatPerformance,
    PurpleNightsAppNexusPerformance,
  ],
  companies: [PhoenixEntertainment, BoltAuto],
  projects: [
    PurpleNights,
    Emilly,
    Koda3,
    GoldenLife,
    HolyMeadow,
    IndianSummer,
    LandOfTheTechnoHorses,
    Lean,
    MonsterLooks,
  ],
  providers: [
    "FACEBOOK_ADS",
    "FACEBOOK_PAGE_INSIGHTS",
    "FACEBOOK_ADS",
    "SNAPCHAT",
    "TWITTER",
    "GOOGLE_ADS",
    "APP_NEXUS",
    "ADROLL",
    "APPNEXUS_NETWORK",
    "APPNEXUS_REPORTS",
    "APPSFLYER",
    "BING_ADS",
    "CRITEO",
    "CSV_FILES",
    "DOUBLECLICK",
    "DOUBLECLICK_BID_MANAGER",
    "DOUBLE_CLICK_CAMPAIGN_MANAGER",
    "DOUBLE_CLICK_FOR_PUBLISHERS",
    "FACEBOOK_POST_INSIGHTS",
    "FLASHTALKING",
    "FTP",
    "FTPS",
    "FULLSTORY",
    "GOOGLE_ANALYTICS",
    "GOOGLE_SEARCH",
    "LINKEDIN_ADS",
    "MIXPANEL",
    "PINTEREST",
    "SALESFORCE",
    "SALESFORCE_IQ",
    "SALESFORCE_MARKTING_CLOUD",
    "SEGMENT",
    "SENDGRID",
    "SFTP",
    "YOUTUBE_ANALYTICS",
  ],
  storyboardTemplates: [
    createStoryboardTemplate({
      name: "Platforms Overview",
      category: "platform",
      description: "An overview of online media performance.",
      color: "black",
    }),
    createStoryboardTemplate({
      name: "Facebook Breakdown",
      category: "platform",
      connections: [{ required: true, provider: "FACEBOOK_ADS" }],
      description: "Deep dive into Facebook performance.",
      color: "rgb(60, 90, 153)",
    }),
    createStoryboardTemplate({
      name: "Instagram Breakdown",
      category: "platform",
      connections: [{ required: true, provider: "FACEBOOK_ADS" }],
      description: "Deep dive into Instagram performance.",
      color:
        "linear-gradient(-90deg, #4863CA 0%, #AB47BC 23%, #FF543F 66%, #FFCD52 100%)",
    }),
    createStoryboardTemplate({
      name: "Youtube Breakdown",
      category: "platform",
      connections: [{ required: true, provider: "GOOGLE_ADS" }],
      description: "Deep dive into YouTube performance.",
      color: "#FF3E00",
    }),
    createStoryboardTemplate({
      name: "Google Breakdown",
      category: "platform",
      connections: [{ required: true, provider: "GOOGLE_ADS" }],
      description: "Deep dive into Google performance Search.",
      color: "linear-gradient(90deg, #4285F4 0%, #4285F4 25%, #0F9D58 25%, #0F9D58 50%, #F4B400 50%, #F4B400 75%, #DB4437 75%, #DB4437 100%)",
    }),
    createStoryboardTemplate({
      name: "Twitter Breakdown",
      category: "platform",
      connections: [{ required: true, provider: "TWITTER" }],
      description: "Deep dive into Twitter performance.",
      color: "rgb(80, 194, 247)",
    }),
    createStoryboardTemplate({
      name: "Snapchat Breakdown",
      category: "platform",
      connections: [{ required: true, provider: "SNAPCHAT" }],
      description: "Deep dive into Snapchat performance.",
      color: "#fffc04",
    }),
    createStoryboardTemplate({
      name: "AppNexus Breakdown",
      category: "platform",
      connections: [{ required: true, provider: "APP_NEXUS" }],
      description: "Deep dive into Appnexus performance.",
      color: "#f78200",
    }),
    createStoryboardTemplate({
      name: "Creatives Overview ",
      category: "creative",
      description: "Overview of creative performance.",
      color: "black",
    }),
    createStoryboardTemplate({
      name: "Paid Social Breakdown",
      category: "creative",
      description: "Breakdown of paid social creative performance.",
      color: "#9C27B0",
    }),
    createStoryboardTemplate({
      name: "Paid Organic Breakdown",
      category: "creative",
      description: "Breakdown of organic social creative performance.",
      color: "#673AB7",
    }),
    createStoryboardTemplate({
      name: "Paid Search Breakdown",
      category: "creative",
      description: "Breakdown of paid search creative performance.",
      color: "#3F51B5",
    }),
    createStoryboardTemplate({
      name: "Organic Search Breakdown",
      category: "creative",
      description: "Breakdown of organic search creative performance.",
      color: "#2196F3",
    }),
    createStoryboardTemplate({
      name: "Display & Video Breakdown",
      category: "creative",
      description: "Breakdown on display and video creative performance.",
      color: "#03A9F4",
    }),
    createStoryboardTemplate({
      name: "Audience Demogaphics Breakdown",
      category: "creative",
      description: "Comparison of creative performance and demographics.",
      color: "#00BCD4",
    }),
    createStoryboardTemplate({
      name: "Audience Overview",
      category: "audience",
      description: "An overview of audience performance.",
      color: "black",
    }),
    createStoryboardTemplate({
      name: "Cross-platform Interest Prediction",
      category: "audience",
      description:
        "Psychographic and Demographic Profiles for Panoramic Audiences",
      color: "#4CAF50",
    }),
    createStoryboardTemplate({
      name: "Awareness Analysis",
      category: "audience",
      description: "An analysis of audience awareness trends.",
      color: "#CDDC39",
    }),
    createStoryboardTemplate({
      name: "Behaviors Analysis",
      category: "audience",
      description: "An analysis of audience behavior trends.",
      color: "#FFEB3B",
    }),
    createStoryboardTemplate({
      name: "Demographics Analysis",
      category: "audience",
      description: "An analysis of audience demographic trends.",
      color: "#FFC107",
    }),
    createStoryboardTemplate({
      name: "Geographics Analysis",
      category: "audience",
      description: "An analysis of audience geographic trends.",
      color: "#FF9800",
    }),
    createStoryboardTemplate({
      name: "Competitive Analysis",
      category: "audience",
      description: "Competitive analysis based on audience traits",
      color: "#FF5722",
    }),
  ],
};

export default data;
