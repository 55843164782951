import React from "react";
import { LocationDescriptor } from "history";
import { useViewportSize } from "./viewport-observer";
import CardRow from "./card-row";
import FlatRow from "./flat-row";

interface ResponsiveListItemProps {
  to: LocationDescriptor;
  title: string;
  text: string;
    
  /**
   * The element provided to this prop will be rendered as CSS grid item
   * with its width set to 2.5rem.
   */
  icon?: JSX.Element;
}

export default function ResponsiveListItem(props: ResponsiveListItemProps) {
  const viewport = useViewportSize();

  if (viewport.isNarrowPlus) {
    return <CardRow {...props} />;
  }

  return <FlatRow {...props} />;
}
