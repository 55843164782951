import { DataSourceProvider } from "./entity-types";
import { pick } from "./utils";

export function getProviderName(provider: DataSourceProvider) {
  return pick(provider, {
    FACEBOOK_ADS: "Facebook Ads",
    FACEBOOK_PAGE_INSIGHTS: "Facebook Page Insights",
    INSTAGRAM: "Instagram",
    SNAPCHAT: "Snapchat",
    TWITTER: "Twitter",
    GOOGLE_ADS: "Google Ads",
    APP_NEXUS: "App Nexus",
    ADROLL: "AdRoll",
    APPNEXUS_NETWORK: "AppNexus Network",
    APPNEXUS_REPORTS: "AppNexus Reports",
    APPSFLYER: "AppsFlyer",
    BING_ADS: "Bing Ads",
    CRITEO: "Criteo",
    CSV_FILES: ".CSV files",
    DOUBLECLICK: "DoubleClick",
    DOUBLECLICK_BID_MANAGER: "DoubleClick Bid Manager",
    DOUBLE_CLICK_CAMPAIGN_MANAGER: "Double Click Campaign Manager",
    DOUBLE_CLICK_FOR_PUBLISHERS: "Double Click for Publishers",
    FACEBOOK_POST_INSIGHTS: "Facebook Post Insights",
    FLASHTALKING: "flashtalking",
    FTP: "FTP",
    FTPS: "FTPS",
    FULLSTORY: "FullStory",
    GOOGLE_ANALYTICS: "Google Analytics",
    GOOGLE_SEARCH: "Google Search",
    LINKEDIN_ADS: "LinkedIn Ads",
    MIXPANEL: "Mixpanel",
    PINTEREST: "Pinterest",
    SALESFORCE: "Salesforce",
    SALESFORCE_IQ: "Salesforce IQ",
    SALESFORCE_MARKTING_CLOUD: "Salesforce Markting Cloud",
    SEGMENT: "Segment",
    SENDGRID: "SendGrid",
    SFTP: "SFTP",
    YOUTUBE_ANALYTICS: "YouTube Analytics",
  });
}

export function getProviderLogoUrl(provider: DataSourceProvider) {
  return pick(provider, {
    FACEBOOK_ADS: "/images/facebook-ads@2x.png",
    FACEBOOK_PAGE_INSIGHTS: "/images/facebook@2x.png",
    INSTAGRAM: "/images/instagram@2x.png",
    SNAPCHAT: "/images/snapchat@2x.png",
    TWITTER: "/images/twitter@2x.png",
    GOOGLE_ADS: "/images/google-ads@2x.png",
    APP_NEXUS: "/images/app-nexus@2x.png",
    ADROLL: "/images/adroll@2x.png",
    APPNEXUS_NETWORK: "/images/app-nexus@2x.png",
    APPNEXUS_REPORTS: "/images/app-nexus@2x.png",
    APPSFLYER: "/images/appsflyer@2x.png",
    BING_ADS: "/images/bing-ads@2x.png",
    CRITEO: "/images/criteo@2x.png",
    CSV_FILES: "/images/csv@2x.png",
    DOUBLECLICK: "/images/doubleclick@2x.png",
    DOUBLECLICK_BID_MANAGER: "/images/doubleclick-bid-manager@2x.png",
    DOUBLE_CLICK_CAMPAIGN_MANAGER: "/images/doubleclick-campaign-manager@2x.png",
    DOUBLE_CLICK_FOR_PUBLISHERS: "/images/doubleclick-for-publishers@2x.png",
    FACEBOOK_POST_INSIGHTS: "/images/facebook@2x.png",
    FLASHTALKING: "/images/flashtalking@2x.png",
    FTP: "/images/ftp@2x.png",
    FTPS: "/images/ftps@2x.png",
    FULLSTORY: "/images/fullstory@2x.png",
    GOOGLE_ANALYTICS: "/images/google-analytics@2x.png",
    GOOGLE_SEARCH: "/images/google-search@2x.png",
    LINKEDIN_ADS: "/images/linkedin-ads@2x.png",
    MIXPANEL: "/images/mixpanel@2x.png",
    PINTEREST: "/images/pinterest@2x.png",
    SALESFORCE: "/images/salesforce@2x.png",
    SALESFORCE_IQ: "/images/salesforce-iq@2x.png",
    SALESFORCE_MARKTING_CLOUD: "/images/salesforce-marketing-cloud@2x.png",
    SEGMENT: "/images/segment@2x.png",
    SENDGRID: "/images/sendgrid@2x.png",
    SFTP: "/images/sftp@2x.png",
    YOUTUBE_ANALYTICS: "/images/youtube-analytics@2x.png",
  });
}

export function isProviderSelfServe(provider: DataSourceProvider) {
  return [
    "FACEBOOK_ADS",
    "FACEBOOK_PAGE_INSIGHTS",
    "FACEBOOK_ADS",
    "SNAPCHAT",
    "TWITTER",
    "GOOGLE_ADS",
    "APP_NEXUS",
  ].includes(provider);
}
