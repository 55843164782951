import React, { useEffect } from "react";
import { useDialogs } from "./dialog-manager";
import Dialog from "./dialog";
import Paragraph from "./paragraph";

interface AddingDataSourceDialogProps {
  name: string;
  onClose: () => void;
}

export default function AddingDataSourceDialog(
  props: AddingDataSourceDialogProps,
) {
  const dialogs = useDialogs();

  // Ignoring timeout cleanup since this is a prototype-only component.
  useEffect(() => {
    setTimeout(() => {
      props.onClose();
      dialogs.closeDialog();
    }, 3000);
  }, []);

  return (
    <Dialog title={`Connecting ${props.name}...`}>
      <Paragraph>
        This should take just a short moment.
      </Paragraph>
    </Dialog>
  );
}
