import React from "react";
import { Button } from "./button";
import { link } from "./paths";
import { matchActive, matchArchived } from "./filters";
import { useDialogs } from "./dialog-manager";
import { useGlobalState } from "./state-manager";
import BlankSlate from "./blank-slate";
import CreateProjectDialog from "./create-project-dialog";
import GridView from "./grid-view";
import ProjectListItem from "./project-card";
import ProjectPicture from "./project-picture";
import ResponsiveButton from "./responsive-button";
import ResponsiveListItem from "./responsive-list-item";

export default function RootProjectsView() {
  const globalState = useGlobalState();
  const dialogs = useDialogs();

  function openDialog() {
    dialogs.openDialog(<CreateProjectDialog />);
  }

  return (
    <GridView
      title="Projects"
      items={globalState.getAllProjects()}
      columns={5}
      defaultFilter={matchActive}
      actions={
        <ResponsiveButton
          onClick={openDialog}
          label="Create Project"
          icon="add"
        />
      }
      tabs={[
        { label: "Active", filter: matchActive },
        { label: "Archived", filter: matchArchived },
      ]}
      renderListItem={project => (
        <ResponsiveListItem
          to={link.toProjectRoot({ projectId: project.id })}
          icon={<ProjectPicture pictureUrl={project.thumbUrl} />}
          title={project.name}
          text={`${project.collaborators.length} collaborators / ${
            project.dataSources.length
          } data sources`}
          key={project.id}
        />
      )}
      renderGridItem={project => (
        <ProjectListItem {...project} key={project.id} />
      )}
      renderBlankSlate={() => (
        <BlankSlate
          illustration="projects"
          title="No Projects"
          instructions="Create projects to start using Panoramic!"
          actions={<Button onClick={openDialog} label="Create Project" theme="blue" />}
        />
      )}
    />
  );
}
