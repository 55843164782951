import React from "react";
import { pick } from "./utils";

interface ExpandIndicatorProps {
  open?: boolean;
  marginLeft?: "auto" | "small";
}

export default function ExpandIndicator(props: ExpandIndicatorProps) {
  const marginLeft = pick(props.marginLeft || "auto", {
    auto: "auto",
    small: "1rem"
  });

  return (
    <div
      style={{
        fontSize: "1rem",
        flexShrink: 0,
        marginLeft,
        transform: `rotate(${props.open ? "180deg" : "0"})`,
        transition: "transform 0.2s",
        transformOrigin: "center 45%",
      }}
    >
      <svg width="1em" height="1em" viewBox="0 0 12 12">
        <path
          d="M10 5.5c0-.28-.22-.5-.5-.5h-7a.495.495 0 0 0-.37.83l3.5 4c.09.1.22.17.37.17s.28-.07.37-.17l3.5-4c.08-.09.13-.2.13-.33z"
          fill="#8FA4B4"
          fillRule="evenodd"
        />
      </svg>
    </div>
  );
}
