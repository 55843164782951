import React from "react";
import { Omit } from "./utils";
import ManagedList, { ManagedListProps, NamedItem } from "./managed-list";
import View from "./view";

type ConsumedProps = "gap" | "columns";

interface ListViewProps<Item extends NamedItem> extends Omit<ManagedListProps<Item>, ConsumedProps> {
  title: string;
}

export default function ListView<Item extends NamedItem>(props: ListViewProps<Item>) {
  const { title, ...fwdProps } = props;

  return (
    <View title={title}>
      <ManagedList
        {...fwdProps}
        gap="hairline"
        columns={1}
      />
    </View>
  );
}
