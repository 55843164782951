import React from "react";
import { Company } from "./entity-types";
import { SaveButton } from "./button";
import ActionsArea from "./actions-area";
import ContentSection from "./content-section";
import ContentView from "./content-view";
import TextInput from "./text-input";

export default function CompanySettingsView(props: Company) {
  return (
    <ContentView title="Company">
      <ContentSection title="General">
        <TextInput label="Name" defaultValue={props.name} />
        <ActionsArea>
          <SaveButton disabled />
        </ActionsArea>
      </ContentSection>
    </ContentView>
  );
}
