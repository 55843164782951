import React from "react";
import { color } from "./stylesheet";
import { Link } from "react-router-dom";
import { LocationDescriptor } from "history";
import { prototypeWarning } from "./utils";
import { useHover } from "./custom-hooks";
import IconButton from "./icon-button";
import Label from "./label";

interface FlatRowProps {
  to: LocationDescriptor;
  title: string;
  text: string;
  
  /**
   * The element provided to this prop will be rendered as CSS grid item
   * with its width set to 2.5rem.
   */
  icon?: JSX.Element;
}

export default function FlatRow(props: FlatRowProps) {
  const { hovered, ...handlers } = useHover();

  return (
    <Link
      {...handlers}
      to={props.to}
      style={{
        display: "grid",
        padding: "0 1.5rem",
        height: "5rem",
        gridTemplateColumns: props.icon
          ? "2.5rem minmax(1rem, 1fr) auto"
          : "minmax(1rem, 1fr) auto",
        gridColumnGap: "1rem",
        alignItems: "center",
        backgroundColor: "white",
        boxShadow: hovered
          ? `0 1px 0 0 rgba(0,0,0,0.10), inset 0.25rem 0 0 0 ${color.BLUE}`
          : "0 1px 0 0 rgba(0,0,0,0.10)",
        transition: "box-shadow 0.2s",
      }}>
      {props.icon}

      <div>
        <Label>{props.title}</Label>
        <Label small color="blue-gray">
          {props.text}
        </Label>
      </div>

      <IconButton icon="more" onClick={prototypeWarning} />
    </Link>
  );
}
