import React from "react";
import { Button, SaveButton } from "./button";
import { Project } from "./entity-types";
import { useDialogs } from "./dialog-manager";
import { useGlobalState } from "./state-manager";
import ActionsArea from "./actions-area";
import ConfirmArchiveProjectDialog from "./confirm-archive-project-dialog";
import ContentSection from "./content-section";
import ContentView from "./content-view";
import Paragraph from "./paragraph";
import TextInput from "./text-input";

export default function ProjectSettingsView(props: Project) {
  const dialogs = useDialogs();
  const globalState = useGlobalState();

  function openDialog() {
    dialogs.openDialog(
      <ConfirmArchiveProjectDialog projectId={props.id} />,
    );
  }

  return (
    <ContentView title="Settings">
      <ContentSection title="General">
        <TextInput label="Name" defaultValue={props.name} />
        <TextInput label="Type" defaultValue="Theatrical" />
        <ActionsArea>
          <SaveButton disabled />
        </ActionsArea>
      </ContentSection>
      <ContentSection title="Danger Zone" rim="danger">
        <Paragraph>
          Archived projects are hidden from the dashboard. You can unarchive a
          project at any time.
        </Paragraph>
        {props.status === "archived" ? (
          <Button
            theme="border"
            onClick={() => {
              globalState.toggleProjectArchival({ projectId: props.id });
            }}>
            Unarchive
          </Button>
        ) : (
          <Button theme="border" onClick={openDialog}>
            Archive
          </Button>
        )}
      </ContentSection>
    </ContentView>
  );
}
