import { generatePath } from "react-router-dom";

export const path = {
  root: "/", // Redirect to Root Projects
  rootUser: "/user",
  rootProjects: "/projects",
  rootCompanies: "/companies",
  rootStoryboards: "/storyboards", // Redirect to Root

  projectRoot: "/projects/:projectId",
  projectStoryboards: "/projects/:projectId/storyboards",
  projectStoryboardDetail: "/projects/:projectId/storyboards/:storyboardId",
  projectCollaborators: "/projects/:projectId/collaborators",
  projectDataSources: "/projects/:projectId/data-sources",
  projectSettings: "/projects/:projectId/settings",

  companyRoot: "/companies/:companyId", // Redirect to Company Settings
  companySettings: "/companies/:companyId/settings",
  companyUsers: "/companies/:companyId/users",
  companyTeams: "/companies/:companyId/teams",
  companyProjects: "/companies/:companyId/projects",

  storyboardRoot: "/storyboards/:storyboardId",
};

// prettier-ignore
export const link = {
  toRoot: () => path.root,
  toRootUser: () => path.rootUser,
  toRootProjects: () => path.rootProjects,
  toRootCompanies: () => path.rootCompanies,
  toRootStoryboards: () => path.rootStoryboards,

  toProjectRoot: (params: { projectId: string }) => generatePath(path.projectRoot, params),
  toProjectStoryboards: (params: { projectId: string }) => generatePath(path.projectStoryboards, params),
  toProjectStoryboardDetail: (params: { projectId: string }) => generatePath(path.projectStoryboardDetail, params),
  toProjectCollaborators: (params: { projectId: string }) => generatePath(path.projectCollaborators, params),
  toProjectDataSources: (params: { projectId: string }) => generatePath(path.projectDataSources, params),
  toProjectSettings: (params: { projectId: string }) => generatePath(path.projectSettings, params),

  toCompanyRoot: (params: { companyId: string }) => generatePath(path.companyRoot, params),
  toCompanySettings: (params: { companyId: string }) => generatePath(path.companySettings, params),
  toCompanyUsers: (params: { companyId: string }) => generatePath(path.companyUsers, params),
  toCompanyTeams: (params: { companyId: string }) => generatePath(path.companyTeams, params),
  toCompanyProjects: (params: { companyId: string }) => generatePath(path.companyProjects, params),

  toStoryboardRoot: (params: { storyboardId: string }) => generatePath(path.storyboardRoot, params),
};
