import { Storyboard, Project } from "./entity-types"

export function sortByStarred(first: Storyboard, second: Storyboard) {
  if (first.starred && second.starred) {
    return 0;
  }

  if (!first.starred && !second.starred) {
    return 0;
  }

  return first.starred ? -1 : 1;
}

export function sortByStatus(first: Project, second: Project) {
  if (first.status === "active" && second.status === "active") {
    return 0;
  }

  if (first.status === "archived" && second.status === "archived") {
    return 0;
  }

  return first.status === "active" ? -1 : 1;
}
