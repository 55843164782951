import React from "react";
import { color } from "./stylesheet";

interface SectionHeaderProps {
  title: string;
}

export default function SectionHeader(props: SectionHeaderProps) {
  return (
    <div
      style={{
        fontWeight: 500,
        fontSize: "1.5rem",
        lineHeight: "2rem",
        marginBottom: "2rem",
        color: color.ALMOST_BLACK,
      }}>
      {props.title}
    </div>
  );
}
