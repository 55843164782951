import React from "react";
import { color, zIndex, position } from "./stylesheet";
import { link } from "./paths";
import { Link } from "react-router-dom";
import { prototypeWarning } from "./utils";
import { sortByStarred } from "./sorts";
import { Storyboard, Project } from "./entity-types";
import { Transition, animated } from "react-spring";
import { useGlobalState } from "./state-manager";
import { useToggle } from "./custom-hooks";
import { useViewportSize } from "./viewport-observer";
import BackButton from "./back-button";
import ExpandIndicator from "./expand-indicator";
import IconButton from "./icon-button";
import IconRow from "./icon-row";
import Label from "./label";
import ProjectPicture from "./project-picture";
import StoryboardCard from "./storyboard-card";
import TopNav from "./top-nav";

interface StoryboardNavProps {
  project: Project;
  storyboard: Storyboard;
  storyboards: Storyboard[];
}

export default function StoryboardNav(props: StoryboardNavProps) {
  const [switcherOpen, toggleSwitcher] = useToggle();
  const globalState = useGlobalState();
  const viewport = useViewportSize();

  function toggleStar() {
    globalState.toggleStoryboardStar({ storyboardId: props.storyboard.id });
  }

  if (viewport.isMediumPlus) {
    return (
      <div
        style={{
          position: position.STICKY,
          zIndex: zIndex.STICKY_NAV,
          top: 0,
        }}>
        <TopNav>
          <BackButton
            marginRight="medium"
            fallbackTo={link.toProjectRoot({ projectId: props.project.id })}
          />
          <Link
            to={link.toProjectRoot({ projectId: props.project.id })}
            style={{ display: "flex", alignItems: "center" }}>
            <ProjectPicture pictureUrl={props.project.thumbUrl} />
            <Label>{props.project.name}</Label>
          </Link>
          <div
            style={{
              fontSize: "1.25rem",
              color: color.LIGHT_BLUE_GRAY,
              margin: "0 1rem",
            }}>
            /
          </div>
          <button
            onClick={toggleSwitcher}
            style={{
              cursor: "pointer",
              display: "flex",
              alignItems: "center",
            }}>
            <Label>{props.storyboard.name}</Label>
            <ExpandIndicator marginLeft="small" open={switcherOpen} />
          </button>
          <IconRow>
            <IconButton
              icon={props.storyboard.starred ? "star" : "empty-star"}
              color={props.storyboard.starred ? "yellow" : "light-blue-gray"}
              onClick={toggleStar}
            />
            <IconButton icon="more" onClick={prototypeWarning} />
          </IconRow>
        </TopNav>
        <Transition
          native
          items={switcherOpen}
          from={{ y: -100 }}
          enter={{ y: 0 }}
          leave={{ y: -100 }}
          config={{ tension: 500, friction: 60 }}>
          {open =>
            open &&
            (style => (
              <animated.div
                style={{
                  // Seems like the typing info is not correct here
                  transform: (style.y as any).interpolate(
                    (y: number) => `translate3d(0, ${y}%, 0)`,
                  ),
                  position: "absolute",
                  width: "100%",
                  display: "grid",
                  gridTemplateColumns: `repeat(${
                    props.storyboards.length
                  }, 18rem)`,
                  gridGap: "1rem",
                  padding: "2.5rem",
                  backgroundColor: "#f2f2f2",
                  overflow: "auto",
                  boxShadow:
                    "0 0 2px 0 rgba(0,0,0,0.20), 0 2px 4px 0 rgba(0,0,0,0.06)",
                }}>
                {props.storyboards.sort(sortByStarred).map(storyboard => (
                  <StoryboardCard
                    {...storyboard}
                    key={storyboard.id}
                    onClick={toggleSwitcher}
                  />
                ))}
              </animated.div>
            ))
          }
        </Transition>
      </div>
    );
  }

  return (
    <TopNav>
      <BackButton
        marginRight="small"
        fallbackTo={link.toProjectRoot({ projectId: props.project.id })}
      />
      <div>
        <Label>{props.storyboard.name}</Label>
        <Label small color="blue-gray">
          {props.project.name}
        </Label>
      </div>
      <IconRow>
        <IconButton
          icon={props.storyboard.starred ? "star" : "empty-star"}
          color={props.storyboard.starred ? "yellow" : "light-blue-gray"}
          onClick={toggleStar}
        />
        <IconButton icon="more" onClick={prototypeWarning} />
      </IconRow>
    </TopNav>
  );
}
