import React, { ReactNode } from "react";
import { color } from "./stylesheet";
import { useViewportSize } from "./viewport-observer";

interface DialogProps {
  title: string;
  children: ReactNode;
}

export default function Dialog(props: DialogProps) {
  const viewport = useViewportSize();

  return (
    <div
      style={{
        maxWidth: "40rem",
        margin: "auto",
        flexGrow: 1,
        padding: viewport.isNarrowPlus
          ? "2rem 3rem 3rem"
          : "1rem 1.5rem 1.5rem",
        backgroundColor: "white",
        borderRadius: "0.25rem",
        boxShadow:
          "0 1px 4px -1px rgba(0,0,0,0.30), 0 4px 24px -2px rgba(0,0,0,0.10)",
        animation: "0.2s panoramic-zoom-in",
        animationFillMode: "both",
      }}>
      <div
        style={{
          fontSize: "2rem",
          lineHeight: "3rem",
          fontWeight: 700,
          marginBottom: "1.5rem",
          color: color.ALMOST_BLACK,
        }}>
        {props.title}
      </div>
      {props.children}
    </div>
  );
}
