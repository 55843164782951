import React from "react";
import { Button } from "./button";
import { Card } from "./card";
import { color } from "./stylesheet";
import { DataSourceProvider } from "./entity-types";
import { useDialogs } from "./dialog-manager";
import { useGlobalState } from "./state-manager";
import * as entityHelpers from "./entity-helpers";
import AddingDataSourceDialog from "./adding-data-source-dialog";
import id from "shortid";

interface DataSourceProviderCard {
  projectId: string;
  provider: DataSourceProvider;
}

export default function DataSourceProviderCard(props: DataSourceProviderCard) {
  const dialogs = useDialogs();
  const globalState = useGlobalState();

  const selfServe = entityHelpers.isProviderSelfServe(props.provider);
  const logoUrl = entityHelpers.getProviderLogoUrl(props.provider);
  const name = entityHelpers.getProviderName(props.provider);

  return (
    <Card padding="regular">
      <div style={{ display: "flex", flexDirection: "column", height: "100%" }}>
        <div
          style={{
            width: "100%",
            paddingBottom: "44.44444%",
            backgroundSize: "contain",
            backgroundImage: `url(${logoUrl})`,
          }}
        />
        <div
          style={{
            fontSize: "1.25rem",
            lineHeight: "1.5rem",
            textAlign: "center",
            color: color.ALMOST_BLACK,
            margin: "1rem 0 1.5rem",
          }}>
          {name}
        </div>
        <div style={{ marginTop: "auto" }}>
          <Button
            fill
            theme="light-blue"
            onClick={() => {
              dialogs.openDialog(
                <AddingDataSourceDialog
                  name={name}
                  onClose={() => {
                    globalState.addDataSource({
                      id: id.generate(),
                      projectId: props.projectId,
                      name: `Official ${name} Account`,
                      provider: props.provider,
                      token: "AXGEUSNARYEVPAYFFQRSNGPRGAT",
                    });
                  }}
                />,
              );
            }}>
            {selfServe ? "Connect" : "Request"}
          </Button>
        </div>
      </div>
    </Card>
  );
}
