import React, { Fragment } from "react";
import { Company } from "./entity-types";
import { link } from "./paths";
import { RootSideNav } from "./root-nav";
import { useViewportSize } from "./viewport-observer";
import BackButton from "./back-button";
import Label from "./label";
import NavLink from "./nav-link";
import SideNav from "./side-nav";
import SwitcherSideNav from "./switcher-side-nav";
import TieredTopNav from "./tiered-top-nav";

function CompanyNavLinks(props: { condensed?: boolean } & Company) {
  return (
    <Fragment>
      <NavLink
        icon="company"
        label="Company"
        to={link.toCompanySettings({ companyId: props.id })}
        condensed={props.condensed}
      />
      <NavLink
        icon="projects"
        label="Projects"
        to={link.toCompanyProjects({ companyId: props.id })}
        condensed={props.condensed}
      />
      <NavLink
        icon="user"
        label="Users"
        to={link.toCompanyUsers({ companyId: props.id })}
        condensed={props.condensed}
      />
      <NavLink
        icon="team"
        label="Teams"
        to={link.toCompanyTeams({ companyId: props.id })}
        condensed={props.condensed}
      />
    </Fragment>
  );
}

interface CompanySideNavProps extends Company {
  companies: Company[];
}

export function CompanySideNav(props: CompanySideNavProps) {
  const viewport = useViewportSize();

  if (viewport.isExtraWidePlus) {
    return (
      <Fragment>
        <RootSideNav condensed />
        <SwitcherSideNav
          switcher={props.companies.length > 1}
          navTitle={<Label>{props.name}</Label>}
          navLinks={<CompanyNavLinks {...props} />}
          renderSwitcherTitle={() => <Label>Companies</Label>}
          renderSwitcherLinks={() => (
            <Fragment>
              {props.companies.map(company => (
                <NavLink
                  key={company.id}
                  icon="company"
                  label={company.name}
                  to={link.toCompanyRoot({ companyId: company.id })}
                />
              ))}
            </Fragment>
          )}
        />
      </Fragment>
    );
  }

  return (
    <SideNav narrow>
      <div
        style={{
          padding: "0.75rem 1rem",
          marginBottom: "2.5rem",
        }}>
        <BackButton fallbackTo={link.toRoot()} />
      </div>
      <CompanyNavLinks {...props} condensed />
    </SideNav>
  );
}

export function CompanyTopNav(props: Company) {
  return (
    <TieredTopNav>
      <Fragment>
        <BackButton fallbackTo={link.toRootCompanies()} marginRight="small" />
        <Label>{props.name}</Label>
      </Fragment>
      <CompanyNavLinks {...props} condensed />
    </TieredTopNav>
  );
}
