import React, {
  createContext,
  ReactNode,
  useReducer,
  Dispatch,
  useContext,
} from "react";
import { noop } from "./utils";
import { Project, DataSourceProvider } from "./entity-types";
import data, { Data } from "./data";
import id from "shortid";

interface CreateProject {
  type: "CREATE_PROJECT";
  payload: { id: string; name: string };
}

interface AddDataSource {
  type: "ADD_DATA_SOURCE";
  payload: {
    projectId: string;
    id: string;
    name: string;
    provider: DataSourceProvider;
    token: string;
  };
}

interface AddStoryboards {
  type: "ADD_STORYBOARDS";
  payload: {
    projectId: string;
    storyboards: {
      id: string;
      name: string;
      description: string;
      color: string;
    }[];
  };
}

interface ToggleStoryboardStar {
  type: "TOGGLE_STORYBOARD_STAR";
  payload: {
    storyboardId: string;
  };
}

interface ToggleConversationResolution {
  type: "TOGGLE_CONVERSATION_RESOLUTION";
  payload: {
    projectId: string;
    conversationId: string;
  };
}

interface ToggleProjectArchival {
  type: "TOGGLE_PROJECT_ARCHIVAL";
  payload: {
    projectId: string;
  };
}

type Action =
  | CreateProject
  | AddDataSource
  | AddStoryboards
  | ToggleStoryboardStar
  | ToggleConversationResolution
  | ToggleProjectArchival;

function log(state: Data, action: Action): Data {
  const newState = reducer(state, action);

  console.log(action.type, action);
  console.log(newState);

  return newState;
}

function reducer(state: Data, action: Action): Data {
  switch (action.type) {
    case "CREATE_PROJECT": {
      const project: Project = {
        id: action.payload.id,
        name: action.payload.name,
        thumbUrl: "/images/land-of-the-techno-horses.png",
        status: "active",
        conversations: [
          {
            id: id.generate(),
            name: "Getting started",
            thumbUrl: "/images/getting-started.png",
            status: "active",
            created: Date.now(),
            comments: [
              {
                id: id.generate(),
                created: Date.now(),
                text:
                  "Hey there! Welcome to your new project. The best way to get started is to connect some data sources. (What’s the point of a project without data, right?)",
                action: "CONNECT_DATA_SOURCES",
                author: {
                  id: "#",
                  name: "Joi",
                  email: "joi@panoramic.ai",
                  avatarUrl: "/images/joi.png",
                },
              },
            ],
          },
        ],
        storyboards: [],
        dataSources: [],
        collaborators: [],
      };

      state.projects.unshift(project);

      return state;
    }

    case "ADD_DATA_SOURCE": {
      const { projectId, ...dataSource } = action.payload;
      const project = state.projects.find(project => project.id === projectId);

      if (!project) {
        console.error("Couldn't add data source to non-existing project");
        return state;
      }

      if (project.dataSources.length === 0) {
        const convo = project.conversations.find(
          convo => convo.name === "Getting started",
        );

        if (convo) {
          convo.comments.push({
            id: id.generate(),
            created: Date.now(),
            text:
              "Great! 🎉 I see you connected first data sources. It will take me some time to sync and analyize the data. In the meantime, you can continue by picking up some storyboards for this project.",
            action: "ADD_STORYBOARDS",
            author: {
              id: "#",
              name: "Joi",
              email: "joi@panoramic.ai",
              avatarUrl: "/images/joi.png",
            },
          });
        }
      }

      project.dataSources.push({ ...dataSource, status: "initializing" });

      return state;
    }

    case "TOGGLE_STORYBOARD_STAR": {
      const storyboard = state.storyboards.find(
        storyboard => storyboard.id === action.payload.storyboardId,
      );

      if (!storyboard) {
        console.error(
          "Couldn't toggle storyboard star on non-existing storyboard",
        );
        return state;
      }

      storyboard.starred = !storyboard.starred;

      return state;
    }

    case "TOGGLE_CONVERSATION_RESOLUTION": {
      const project = state.projects.find(
        project => project.id === action.payload.projectId,
      );

      if (!project) {
        console.error(
          "Couldn't toggle conversation resolution in non-existing project",
        );
        return state;
      }

      const conversation = project.conversations.find(
        conversation => conversation.id === action.payload.conversationId,
      );

      if (!conversation) {
        console.error(
          "Couldn't toggle conversation resolution on non-existing conversation",
        );
        return state;
      }

      conversation.status =
        conversation.status === "active" ? "resolved" : "active";

      return state;
    }

    case "TOGGLE_PROJECT_ARCHIVAL": {
      const project = state.projects.find(
        project => project.id === action.payload.projectId,
      );

      if (!project) {
        console.error("Couldn't toggle archival on non-existing project");
        return state;
      }

      project.status = project.status === "active" ? "archived" : "active";

      return state;
    }

    case "ADD_STORYBOARDS": {
      const project = state.projects.find(
        project => project.id === action.payload.projectId,
      );

      if (!project) {
        console.error("Couldn't add data source to non-existing project");
        return state;
      }

      if (project.storyboards.length === 0) {
        const convo = project.conversations.find(
          convo => convo.name === "Getting started",
        );

        if (convo) {
          convo.comments.push({
            id: id.generate(),
            created: Date.now(),
            text:
              "Wonderful. Your storyboards have been added — remember to star the ones you like! ⭐️ This concludes your project setup. Feel free to resolve this conversation with the checkmark in the top right corner of this card.",
            author: {
              id: "#",
              name: "Joi",
              email: "joi@panoramic.ai",
              avatarUrl: "/images/joi.png",
            },
          });
        }
      }

      const storyboards = action.payload.storyboards.map(s => ({
        ...s,
        id: id.generate(),
        connections: [],
        starred: false,
        project,
      }));
      project.storyboards.push(...storyboards);
      state.storyboards.push(...storyboards);

      return state;
    }
  }
}

interface ContextValue {
  state: Data;
  dispatch: Dispatch<Action>;
}

export const StateContext = createContext<ContextValue>({
  state: data,
  dispatch: noop,
});

interface StateManagerProps {
  children: ReactNode;
}

export default function StateManager(props: StateManagerProps) {
  const [state, dispatch] = useReducer(log, data);

  return (
    <StateContext.Provider value={{ state, dispatch }}>
      {props.children}
    </StateContext.Provider>
  );
}

export function useGlobalState() {
  const { state, dispatch } = useContext(StateContext);

  return {
    // Selectors

    getAllProjects() {
      return state.projects;
    },
    getProject(id: string) {
      return state.projects.find(project => project.id === id);
    },
    getAllCompanies() {
      return state.companies;
    },
    getCompany(id: string) {
      return state.companies.find(company => company.id === id);
    },
    getStoryboard(id: string) {
      return state.storyboards.find(storyboard => storyboard.id === id);
    },
    getProviders() {
      return state.providers;
    },
    getStoryboardTemplates() {
      return state.storyboardTemplates;
    },

    // Actions

    addDataSource(payload: AddDataSource["payload"]) {
      dispatch({
        type: "ADD_DATA_SOURCE",
        payload: { ...payload, id: id.generate() },
      });
    },
    addStoryboards(payload: AddStoryboards["payload"]) {
      dispatch({
        type: "ADD_STORYBOARDS",
        payload,
      });
    },
    createProject(payload: CreateProject["payload"]) {
      dispatch({
        type: "CREATE_PROJECT",
        payload,
      });
    },
    toggleConversationResolution(
      payload: ToggleConversationResolution["payload"],
    ) {
      dispatch({
        type: "TOGGLE_CONVERSATION_RESOLUTION",
        payload,
      });
    },
    toggleProjectArchival(payload: ToggleProjectArchival["payload"]) {
      dispatch({
        type: "TOGGLE_PROJECT_ARCHIVAL",
        payload,
      });
    },
    toggleStoryboardStar(payload: ToggleStoryboardStar["payload"]) {
      dispatch({
        type: "TOGGLE_STORYBOARD_STAR",
        payload,
      });
    },
  };
}
