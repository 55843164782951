import React from "react";
import { Button } from "./button";
import { Company } from "./entity-types";
import { useDialogs } from "./dialog-manager";
import BlankSlate from "./blank-slate";
import CreateTeamDialog from "./create-team-dialog";
import ListView from "./list-view";
import ResponsiveButton from "./responsive-button";
import ResponsiveListItem from "./responsive-list-item";

export default function CompanyTeamsView(props: Company) {
  const dialogs = useDialogs();

  function openDialog() {
    dialogs.openDialog(<CreateTeamDialog />);
  }

  return (
    <ListView
      title="Teams"
      items={props.teams}
      actions={<ResponsiveButton onClick={openDialog} label="Create Team" icon="add" />}
      renderItem={team => (
        <ResponsiveListItem
          key={team.id}
          to="/not-implemented"
          title={team.name}
          text={`${team.members.length} members`}
        />
      )}
      renderBlankSlate={() => (
        <BlankSlate
          illustration="teams"
          title="No Teams"
          instructions="Group users in teams to make it easier to assign them to projects."
          actions={<Button onClick={openDialog} label="Create Team" theme="blue" />}
        />
      )}
    />
  );
}
