import React from "react";
import { Button } from "./button";
import { Project } from "./entity-types";
import { useDialogs } from "./dialog-manager";
import AddCollaboratorsDialog from "./add-collaborators-dialog";
import BlankSlate from "./blank-slate";
import ListView from "./list-view";
import ResponsiveButton from "./responsive-button";
import ResponsiveListItem from "./responsive-list-item";
import UserPicture from "./user-picture";

export default function ProjectCollaboratorsView(props: Project) {
  const dialogs = useDialogs();

  function openDialog() {
    dialogs.openDialog(<AddCollaboratorsDialog />);
  }

  return (
    <ListView
      title="Collaborators"
      items={props.collaborators}
      actions={
        <ResponsiveButton
          onClick={openDialog}
          label="Add Collaborators"
          icon="add"
        />
      }
      renderItem={user => (
        <ResponsiveListItem
          key={user.id}
          to="/not-implemented"
          icon={<UserPicture pictureUrl={user.avatarUrl} />}
          title={user.name}
          text={user.email}
        />
      )}
      renderBlankSlate={() => (
        <BlankSlate
          illustration="users"
          title="No Collaborators"
          instructions="Add existing or new users to this project to give them access."
          actions={
            <Button
              onClick={openDialog}
              label="Add Collaborators"
              theme="blue"
            />
          }
        />
      )}
    />
  );
}
