/**
 * Functional alternative to a switch/case statement.
 * 
 * Note that unlike a switch/case, a pick call actually evaluates all its
 * values (branches). Don't use it for expensive operations or anything
 * that has side-effects.
 */

export function pick<Lookup, Key extends keyof Lookup>(key: Key, lookup: Lookup) {
  return lookup[key];
}

/**
 * Divides a list into groups based on the grouping function.
 */

export function group<Item, GroupName extends string>(list: Item[], getGroup: (item: Item) => GroupName) {
  return list.reduce<{[Group in GroupName]: Item[]}>((acc, item) => {
    const groupName = getGroup(item)
    const group = acc[groupName];
    
    if (group) {
      group.push(item);
      return acc;
    }

    acc[groupName] = [item];
    return acc;
  }, {} as any);
}

/**
 * Informs user that action is unavailable in the prototype.
 * 
 * In prod, make sure there are no references left to this function!
 */

export function prototypeWarning() {
  window.alert("This action is not available in this prototype. Sorry!");
}

/**
 * Does nothing.
 * 
 * Useful as a fallback function when setting up context etc... Logs to the console
 * for debugging purposes.
 */

export function noop(): void {
  console.log("Noop function called...");
}

/**
 * Is the current browser Safari?
 * 
 * This is pretty naive, should be checked/replaced for prod.
 */

export const isSafari =
  /safari/gi.test(navigator.userAgent) && !/chrome/gi.test(navigator.userAgent);

/**
 * Extracts Props from a Function Component
 * 
 * This is useful when you have a component that has inferred types which you
 * want to extend from a wrapped component.
 */

export type Props<ComponentType extends (props?: any) => any> = Parameters<ComponentType>[0]

/**
 * Removes props from an interface
 * 
 * Very handy for removing "consumed" props from a interface before using
 * it to extend a wrapped component.
 */

export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
