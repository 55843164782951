import React from "react";
import { Link, Route } from "react-router-dom";
import { pick } from "./utils";
import { useHover } from "./custom-hooks";
import Icon from "./icon";

interface BackButtonProps {
  marginRight?: "none" | "small" | "medium";
  fallbackTo: string;
}

export default function BackButton(props: BackButtonProps) {
  const { hovered, ...handlers } = useHover();

  return (
    <Route>
      {route => (
        <Link
          {...handlers}
          to={
            (route.location.state && route.location.state.returnTo) ||
            props.fallbackTo
          }
          style={{
            marginRight: pick(props.marginRight || "none", {
              none: "0",
              small: "1rem",
              medium: "1.5rem",
            }),
          }}>
          <div
            style={{
              transform: hovered ? "translateX(-0.25rem)" : "translateX(0)",
              transition: "0.2s transform",
            }}>
            <Icon name="back" />
          </div>
        </Link>
      )}
    </Route>
  );
}
