import React from "react";
import { color } from "./stylesheet";
import { useViewportSize } from "./viewport-observer";
import GradientSlate from "./gradient-slate";
import Illustration, { IllustrationName } from "./illustration";

export interface BlankSlateProps {
  title: string;
  instructions: string;
  illustration?: IllustrationName;
  actions?: JSX.Element;
}

export default function BlankSlate(props: BlankSlateProps) {
  const viewport = useViewportSize();

  return (
    <GradientSlate>
      {props.illustration && (
        <div
          style={{
            margin: viewport.isMediumPlus ? "-5.5rem 0 3rem" : "0 0 2rem",
          }}>
          <Illustration
            name={props.illustration}
            size={
              viewport.isMediumPlus
                ? "large"
                : viewport.isNarrowPlus
                ? "medium"
                : "small"
            }
          />
        </div>
      )}
      <div
        style={{
          fontWeight: 500,
          fontSize: "1.5rem",
          lineHeight: "2rem",
          marginBottom: "1.5rem",
          color: color.ALMOST_BLACK,
          maxWidth: "18rem",
          textAlign: "center",
        }}>
        {props.title}
      </div>
      <div
        style={{
          fontSize: "1.25rem",
          lineHeight: "1.5rem",
          marginBottom: "3rem",
          color: color.STEEL,
          maxWidth: "18rem",
          textAlign: "center",
        }}>
        {props.instructions}
      </div>
      {props.actions}
    </GradientSlate>
  );
}
