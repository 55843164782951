import React from "react";
import { color } from "./stylesheet";
import { Conversation, Project } from "./entity-types";
import { link } from "./paths";
import { Link } from "react-router-dom";
import { matchActive, matchResolved, matchStarred } from "./filters";
import { useViewportSize } from "./viewport-observer";
import ConversationCard from "./conversation-card";
import GradientSlate from "./gradient-slate";
import Grid from "./grid";
import ManagedList from "./managed-list";
import StoryboardCard from "./storyboard-card";
import Tab from "./tab";
import Toolbar from "./toolbar";
import View from "./view";

function ProjectStoryboardsView(props: Project) {
  const starredStoryboards = props.storyboards.filter(matchStarred);

  return (
    <div style={{ marginLeft: "2rem" }}>
      <Toolbar>
        <Tab label="Starred" />
      </Toolbar>
      {starredStoryboards.length === 0 ? (
        <GradientSlate>
          <div
            style={{
              textAlign: "center",
              color: color.BLUE_GRAY,
              fontSize: "1.25rem",
              lineHeight: "1.5rem",
              margin: "0 -1rem 1rem",
            }}>
            You haven't starred any storyboards yet!
          </div>
          <Link
            to={link.toProjectStoryboards({ projectId: props.id })}
            style={{
              textTransform: "uppercase",
              color: color.BLUE_GRAY,
              fontSize: "1rem",
              lineHeight: "1.25rem",
              fontWeight: 600,
            }}>
            View storyboards
          </Link>
        </GradientSlate>
      ) : (
        <Grid columns={1} gap="small">
          {starredStoryboards.map(storyboard => (
            <StoryboardCard key={storyboard.id} {...storyboard} />
          ))}
        </Grid>
      )}
    </div>
  );
}

interface ProjectConversationsViewProps {
  conversations: Conversation[];
}

function ProjectConversationsView(props: ProjectConversationsViewProps) {
  return (
    <div style={{ flex: "0 0 58rem" }}>
      <ManagedList
        columns={1}
        gap="regular"
        items={props.conversations}
        search={false}
        defaultFilter={matchActive}
        tabs={[
          { label: "Active", filter: matchActive },
          { label: "Resolved", filter: matchResolved },
        ]}
        renderItem={conversation => (
          <ConversationCard key={conversation.id} {...conversation} />
        )}
      />
    </div>
  );
}

export default function ProjectHomeView(props: Project) {
  const viewport = useViewportSize();

  return (
    <View title="Home">
      {viewport.isWidePlus ? (
        <div
          style={{
            display: "flex",
            marginTop: "-2rem", // Hack to offset the Toolbar margins that don't collapse when wrapper in a flex container
          }}>
          <ProjectConversationsView {...props} />
          <ProjectStoryboardsView {...props} />
        </div>
      ) : (
        <ProjectConversationsView {...props} />
      )}
    </View>
  );
}
