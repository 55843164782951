import React from "react";
import { Button } from "./button";
import { Company } from "./entity-types";
import { link } from "./paths";
import { useDialogs } from "./dialog-manager";
import BlankSlate from "./blank-slate";
import CreateProjectDialog from "./create-project-dialog";
import ListView from "./list-view";
import ProjectPicture from "./project-picture";
import ResponsiveButton from "./responsive-button";
import ResponsiveListItem from "./responsive-list-item";

export default function CompanyProjectsView(props: Company) {
  const dialogs = useDialogs();

  function openDialog() {
    dialogs.openDialog(<CreateProjectDialog />);
  }

  return (
    <ListView
      title="Projects"
      items={props.projects}
      actions={<ResponsiveButton onClick={openDialog} label="Create Project" icon="add" />}
      renderItem={project => (
        <ResponsiveListItem
          key={project.id}
          to={link.toProjectRoot({ projectId: project.id })}
          icon={<ProjectPicture pictureUrl={project.thumbUrl} />}
          title={project.name}
          text={`${project.collaborators.length} collaborators / ${project.dataSources.length} data sources`}
        />
      )}
      renderBlankSlate={() => (
        <BlankSlate
          illustration="projects"
          title="No Projects"
          instructions="Create projects in this company."
          actions={<Button onClick={openDialog} label="Create Project" theme="blue" />}
        />
      )}
    />
  );
}
