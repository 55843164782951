import React, { ReactNode } from "react";

interface ActionsAreaProps {
  children: ReactNode;
}

export default function ActionsArea(props: ActionsAreaProps) {
  return (
    <div
      style={{
        marginTop: "3.5rem",
        display: "flex",
        justifyContent: "space-between",
      }}>
      {props.children}
    </div>
  );
}
