import React, { ReactNode } from "react";
import { height, position } from "./stylesheet";
import { useToggle } from "./custom-hooks";
import ExpandIndicator from "./expand-indicator";
import PrimitiveButton from "./primitive-button";

function Divider() {
  return (
    <div
      style={{
        margin: "0 2rem",
        height: "1px",
        backgroundColor: "#e2e2e2",
        marginTop: "-1px", // This element should not take up space so that it doesn't end up misaligning the flow of the document
      }}
    />
  );
}

interface SecondaryNavProps {
  navTitle: ReactNode;
  navLinks: ReactNode;
  renderSwitcherTitle: () => ReactNode;
  renderSwitcherLinks: () => ReactNode;
  switcher?: boolean;
}

export default function SwitcherSideNav(props: SecondaryNavProps) {
  const [open, toggleSwitcher] = useToggle();
  const { switcher = true } = props;

  return (
    <nav
      style={{
        position: position.STICKY,
        height: "100vh",
        top: 0,
        flex: "0 0 20rem",
        boxShadow: "1px 0 0 0 #e6e6e6",
        overflow: "auto",
      }}>
      <PrimitiveButton
        onClick={switcher ? toggleSwitcher : undefined}
        style={{
          display: "block",
          cursor: switcher ? "pointer" : "initial",
          width: "100%",
          minHeight: height.TALL_TOP_NAV, // Want this to match Top Nav
        }}>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            padding: "1.5rem 2rem",
          }}>
          {open ? props.renderSwitcherTitle() : props.navTitle}
          {switcher && <ExpandIndicator open={open} />}
        </div>
      </PrimitiveButton>

      <Divider />

      <div style={{ padding: "1.5rem 1rem" }}>
        {open ? (
          <div onClick={toggleSwitcher}>{props.renderSwitcherLinks()}</div>
        ) : (
          props.navLinks
        )}
      </div>
    </nav>
  );
}
