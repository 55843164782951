import React, { Fragment } from "react";
import { Button, CloseDialogButton } from "./button";
import { color } from "./stylesheet";
import { group } from "./utils";
import { isProviderSelfServe } from "./entity-helpers";
import { useDialogs } from "./dialog-manager";
import { useGlobalState } from "./state-manager";
import DataSourceProviderCard from "./data-source-provider-card";
import Grid from "./grid";
import LargeDialog from "./large-dialog";
import ListGroupLabel from "./group-label";

interface AddDataSourcesDialogProps {
  projectId: string;
}

export default function AddDataSourcesDialog(props: AddDataSourcesDialogProps) {
  const globalState = useGlobalState();
  const dialogs = useDialogs();

  const groups = group(globalState.getProviders(), provider =>
    isProviderSelfServe(provider) ? "selfServe" : "customMade",
  );

  const project = globalState.getProject(props.projectId);

  if (!project) {
    dialogs.closeDialog();
    return null;
  }

  return (
    <LargeDialog
      image="data-sources"
      title="Choose from wide selection of data sources"
      description="Connect our self-serve and custom-made integrations and get all your data in one place!"
      body={
        <Fragment>
          <ListGroupLabel>Self-serve</ListGroupLabel>
          <Grid columns={4} gap="regular">
            {groups.selfServe.map(provider => (
              <DataSourceProviderCard
                key={provider}
                projectId={project.id}
                provider={provider}
              />
            ))}
          </Grid>
          <ListGroupLabel>Custom-made</ListGroupLabel>
          <Grid columns={4} gap="regular">
            {groups.customMade.map(provider => (
              <DataSourceProviderCard
                key={provider}
                projectId={project.id}
                provider={provider}
              />
            ))}
          </Grid>
        </Fragment>
      }
      actions={
        <Fragment>
          <CloseDialogButton />
          <div
            style={{
              color: color.STEEL,
              fontSize: "1.2rem",
              lineHeight: "2rem",
            }}>
            {project.dataSources.length} data sources connected
          </div>
          <Button autoFocus onClick={() => dialogs.closeDialog()} theme="blue">
            Done
          </Button>
        </Fragment>
      }
    />
  );
}
