import React from "react";
import { Card } from "./card";
import { pick } from "./utils";
import { useViewportSize } from "./viewport-observer";
import * as Recharts from "recharts";

interface ChartProps {
  small: boolean;
}

function getMargin(small: boolean) {
  return {
    right: small ? 10 : 50,
    top: small ? 10 : 50,
    bottom: small ? 10 : 20,
  };
}

function BarChart(props: ChartProps) {
  const data = [
    { name: "AdSet 1", uv: 4000, pv: 2400, amt: 2400 },
    { name: "AdSet 2", uv: 3000, pv: 1398, amt: 2210 },
    { name: "AdSet 3", uv: 2000, pv: 9800, amt: 2290 },
    { name: "AdSet 4", uv: 2780, pv: 3908, amt: 2000 },
    { name: "AdSet 5", uv: 1890, pv: 4800, amt: 2181 },
    { name: "AdSet 6", uv: 2390, pv: 3800, amt: 2500 },
    { name: "AdSet 7", uv: 3490, pv: 4300, amt: 2100 },
  ];

  // prettier-ignore
  return (
    <Recharts.ResponsiveContainer width="100%" height={props.small ? 300 : 500}>
      <Recharts.BarChart data={data} margin={getMargin(props.small)}>
        <Recharts.CartesianGrid strokeDasharray="3 3" />
        <Recharts.XAxis dataKey="name" />
        <Recharts.YAxis />
        <Recharts.Tooltip />
        <Recharts.Bar dataKey="pv" fill="#8884d8" />
        <Recharts.Bar dataKey="uv" fill="#82ca9d" />
      </Recharts.BarChart>
    </Recharts.ResponsiveContainer>
  );
}

function AreaChart(props: ChartProps) {
  const data = [
    { name: "Monday", uv: 4000, pv: 2400, amt: 2400 },
    { name: "Tuesday", uv: 3000, pv: 1398, amt: 2210 },
    { name: "Wednesday", uv: 2000, pv: 9800, amt: 2290 },
    { name: "Thursday", uv: 2780, pv: 3908, amt: 2000 },
    { name: "Friday", uv: 1890, pv: 4800, amt: 2181 },
  ];

  // prettier-ignore
  return (
    <Recharts.ResponsiveContainer width="100%" height={props.small ? 300 : 500}>
      <Recharts.AreaChart data={data} margin={getMargin(props.small)}>
        <Recharts.CartesianGrid strokeDasharray="3 3" />
        <Recharts.XAxis dataKey="name" />
        <Recharts.YAxis />
        <Recharts.Tooltip />
        <Recharts.Area type="monotone" dataKey="uv" stackId="1" stroke="#8884d8" fill="#8884d8" />
        <Recharts.Area type="monotone" dataKey="pv" stackId="1" stroke="#82ca9d" fill="#82ca9d" />
        <Recharts.Area type="monotone" dataKey="amt" stackId="1" stroke="#ffc658" fill="#ffc658" />
      </Recharts.AreaChart>
    </Recharts.ResponsiveContainer>
  );
}

function LineChart(props: ChartProps) {
  const data = [
    { name: "Week 1", uv: 2780, pv: 3908, amt: 2000 },
    { name: "Week 2", uv: 1890, pv: 4800, amt: 2181 },
    { name: "Week 3", uv: 2390, pv: 3800, amt: 2500 },
    { name: "Week 4", uv: 3490, pv: 4300, amt: 2100 },
  ];

  // prettier-ignore
  return (
    <Recharts.ResponsiveContainer width="100%" height={props.small ? 300 : 500}>
      <Recharts.LineChart data={data} margin={getMargin(props.small)}>
        <Recharts.XAxis dataKey="name" />
        <Recharts.YAxis />
        <Recharts.CartesianGrid strokeDasharray="3 3" />
        <Recharts.Tooltip />
        <Recharts.Line type="monotone" dataKey="pv" stroke="#8884d8" activeDot={{ r: 8 }} />
        <Recharts.Line type="monotone" dataKey="uv" stroke="#82ca9d" />
      </Recharts.LineChart>
    </Recharts.ResponsiveContainer>
  );
}

function ScatterChart(props: ChartProps) {
  const data = [
    { x: 100, y: 200, z: 200 },
    { x: 120, y: 100, z: 260 },
    { x: 170, y: 300, z: 400 },
    { x: 140, y: 250, z: 280 },
    { x: 150, y: 400, z: 500 },
    { x: 110, y: 280, z: 200 },
  ];

  // prettier-ignore
  return (
    <Recharts.ResponsiveContainer width="100%" height={props.small ? 300 : 500}>
    <Recharts.ScatterChart margin={getMargin(props.small)}>
      <Recharts.Scatter data={data} fill="#8884d8" />
      <Recharts.CartesianGrid />
      <Recharts.XAxis dataKey={"x"} type="number" />
      <Recharts.YAxis dataKey={"y"} type="number" />
      <Recharts.Tooltip cursor={{ strokeDasharray: "3 3" }} />
    </Recharts.ScatterChart>
    </Recharts.ResponsiveContainer>
  );
}

interface ChartCardProps {
  type?: "line" | "area" | "bar" | "scatter";
}

export default function ChartCard(props: ChartCardProps) {
  const viewport = useViewportSize();

  const ChartComponent = pick(props.type || "line", {
    line: LineChart,
    area: AreaChart,
    bar: BarChart,
    scatter: ScatterChart,
  });

  return (
    <div style={{ marginBottom: "2rem" }}>
      <Card
        padding={
          viewport.isMediumPlus
            ? "huge"
            : viewport.isNarrowPlus
            ? "regular"
            : "small"
        }>
        <ChartComponent small={!viewport.isMediumPlus} />
      </Card>
    </div>
  );
}
