import { useState, useLayoutEffect } from "react";

export function useHover() {
  const [state, setState] = useState(false);
  return {
    hovered: state,
    onMouseEnter: () => setState(true),
    onMouseLeave: () => setState(false),
  };
}

export function useToggle(initial: boolean = false): [boolean, () => void] {
  const [state, setState] = useState(initial);
  return [state, () => setState(state => !state)];
}

export function useMedia(query: string, defaultState: boolean = false) {
  const [state, setState] = useState(defaultState);

  useLayoutEffect(
    () => {
      const mediaQuery = window.matchMedia(query);
      setState(mediaQuery.matches);

      function handleChange() {
        setState(mediaQuery.matches);
      }

      mediaQuery.addEventListener("change", handleChange);

      return () => {
        mediaQuery.removeEventListener("change", handleChange);
      };
    },
    [query],
  );

  return state;
}
