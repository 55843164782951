import React from "react";
import { pick } from "./utils";

interface UserPictureProps {
  size?: "large" | "medium" | "small";
  pictureUrl: string;
}

export default function UserPicture(props: UserPictureProps) {
  const size = pick(props.size || "medium", {
    large: "3.5rem", // 3rem incl. optical adjustment
    medium: "3rem", // 2.5rem incl. optical adjustment
    small: "2.166667rem", // 2rem incl. optical adjustment
  });

  return (
    <div
      style={{
        width: size,
        height: size,
        margin: props.size === "small" ? "-0.0833334rem" : "-0.25rem", // Optical adjustment for circular element
        backgroundColor: "#e2e2e2",
        backgroundImage: `url(${props.pictureUrl})`,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        borderRadius: "50%",
        flexShrink: 0,
        flexGrow: 0,
        boxShadow: "inset 0 0 0 1px rgba(0,0,0,0.1)",
      }}
    />
  );
}
