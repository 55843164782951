import React, { createContext, ReactNode, useContext } from "react";
import { useMedia } from "./custom-hooks";

interface ViewportSize {
  isNarrowPlus: boolean;
  isMediumPlus: boolean;
  isWidePlus: boolean;
  isExtraWidePlus: boolean;
}

const ViewportContext = createContext({
  isNarrowPlus: false,
  isMediumPlus: false,
  isWidePlus: false,
  isExtraWidePlus: false,
});

interface ViewportObserverProps {
  children: ReactNode;
}

export default function ViewportObserver(props: ViewportObserverProps) {
  // Using ems instead of pixels to respect user-defined font size.
  const isNarrowPlus = useMedia("(min-width: 30em)");
  const isMediumPlus = useMedia("(min-width: 54em)");
  const isWidePlus = useMedia("(min-width: 70em)");
  const isExtraWidePlus = useMedia("(min-width: 90em)");

  return (
    <ViewportContext.Provider
      value={{
        isNarrowPlus,
        isMediumPlus,
        isWidePlus,
        isExtraWidePlus,
      }}>
      {props.children}
    </ViewportContext.Provider>
  );
}

/**
 * Returns an object containing info about the current viewport size.
 *
 * The values indicate the *start* of a range, not necessarily the actual
 * current viewport size. Eg. `isMediumPlus` means that the viewport is
 * currently at least 54ems, but it could also be wider. This means that
 * multiple of these values can be true at the same time.
 */
export function useViewportSize(): ViewportSize {
  return useContext(ViewportContext);
}
