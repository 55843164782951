import React from "react";
import { Omit } from "./utils";
import { useViewportSize } from "./viewport-observer";
import ManagedList, { ManagedListProps, NamedItem } from "./managed-list";
import View from "./view";

type ConsumedProps = "renderItem" | "gap";

interface GridViewProps<Item extends NamedItem>
  extends Omit<ManagedListProps<Item>, ConsumedProps> {
  title: string;
  columns: 5 | 4;
  items: Item[]; // Necessary for type inferrence
  renderListItem: (item: Item) => JSX.Element;
  renderGridItem: (item: Item) => JSX.Element;
}

export default function GridView<Item extends NamedItem>(props: GridViewProps<Item>) {
  const viewport = useViewportSize();
  const { title, columns, ...fwdProps } = props;

  return (
    <View title={title}>
      <ManagedList
        {...fwdProps}
        gap={viewport.isMediumPlus ? "regular" : "hairline"}
        columns={viewport.isWidePlus ? columns : viewport.isMediumPlus ? columns - 1 : 1}
        renderItem={viewport.isMediumPlus ? props.renderGridItem : props.renderListItem}
      />
    </View>
  );
}
