import React, { ReactNode } from "react";

interface IconRowProps {
  children: ReactNode;
}

export default function IconRow(props: IconRowProps) {
  return (
    <div
      style={{
        display: "grid",
        gridAutoFlow: "column",
        marginLeft: "auto",
        columnGap: "0.75rem",
      }}>
      {props.children}
    </div>
  );
}
