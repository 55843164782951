import React, { ButtonHTMLAttributes } from "react";

export type PrimitiveButtonProps = ButtonHTMLAttributes<HTMLButtonElement>;

/**
 * Simply a button that has default `type="button"`. This prevents accidental
 * form submissions caused by the default DOM button type.
 */

export default function PrimitiveButton(props: PrimitiveButtonProps) {
  return <button type="button" {...props} />;
}
