import React, { ReactNode, CSSProperties, HTMLAttributes } from "react";
import { Link, LinkProps } from "react-router-dom";
import { pick } from "./utils";
import { useHover } from "./custom-hooks";

interface SharedCardProps {
  rim?: "danger" | "none";
  padding?: "none" | "small" | "regular" | "huge";
  children: ReactNode;
}

function getSharedStyle(props: Pick<SharedCardProps, "padding" | "rim">) {
  const hasRim = props.rim === "danger" || undefined;

  const style: CSSProperties = {
    borderWidth: hasRim && "0.5rem 0 0 0",
    borderStyle: hasRim && "solid",
    borderImage: hasRim &&
      "repeating-linear-gradient(45deg, black, black 10px, #FFE829 10px, #FFE829 20px) 6",
    display: "block",
    backgroundColor: "white",
    borderRadius: "0.25rem",
    boxShadow: "0 0 3px -1px rgba(0,0,0,0.30), 0 4px 6px -2px rgba(0,0,0,0.10)",
    padding: pick(props.padding || "none", {
      none: "0",
      small: "1rem",
      regular: "1.5rem",
      huge: "2rem 3rem 3rem",
    }),
  };

  return style;
}

type CardProps = SharedCardProps & HTMLAttributes<HTMLDivElement>;

export function Card(props: CardProps) {
  const { padding, rim, children, ...fwdProps } = props;

  return (
    <div {...fwdProps} style={getSharedStyle({ padding, rim })}>
      {children}
    </div>
  );
}

type LinkCardProps = SharedCardProps & LinkProps;

export function LinkCard(props: LinkCardProps) {
  const { hovered, ...handlers } = useHover();
  const { padding, rim, children, ...fwdProps } = props;
  const style = getSharedStyle({ padding, rim });

  return (
    <Link
      {...handlers}
      {...fwdProps}
      style={{
        ...style,
        boxShadow: hovered
          ? "0 0 4px -1px rgba(0,0,0,0.30), 0 6px 6px -2px rgba(0,0,0,0.10)"
          : style.boxShadow,
        transform: hovered ? "translateY(-0.25rem)" : "translateY(0)",
        transition: "transform 0.2s, box-shadow 0.2s",
      }}>
      {children}
    </Link>
  );
}
