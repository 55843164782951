import React, { Fragment, ReactNode } from "react";
import { useViewportSize } from "./viewport-observer";
import Title from "./title";

interface ViewProps {
  title: string;
  children: ReactNode;
}

export default function View(props: ViewProps) {
  const viewport = useViewportSize();

  return (
    <Fragment>
      {viewport.isMediumPlus && (
        <div style={{ marginBottom: "2.5rem" }}>
          <Title>{props.title}</Title>
        </div>
      )}
      {props.children}
    </Fragment>
  );
}
