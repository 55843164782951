import React, { useState, Fragment } from "react";
import { Button, CloseDialogButton } from "./button";
import { color } from "./stylesheet";
import { group } from "./utils";
import { useDialogs } from "./dialog-manager";
import { useGlobalState } from "./state-manager";
import Grid from "./grid";
import LargeDialog from "./large-dialog";
import ListGroupLabel from "./group-label";
import StoryboardTemplateCard from "./storyboard-template-card";

function useSelection() {
  const [state, setState] = useState<string[]>([]);

  function toggleSelection(id: string) {
    setState(ids => {
      if (ids.includes(id)) {
        return ids.filter(existingId => existingId !== id);
      }
      return [id, ...ids];
    });
  }

  return { selection: state, toggleSelection};
}

export default function AddStoryboardsDialog(props: { projectId: string }) {
  const globalState = useGlobalState();
  const dialogs = useDialogs();
  const { selection, toggleSelection } = useSelection();

  const templates = globalState.getStoryboardTemplates();
  const groups = group(templates, template => template.category);

  return (
    <LargeDialog
      title="Tailor-made storyboards"
      description="Connect our self-serve and custom-made integrations and get all your data in one place."
      body={
        <Fragment>
          <ListGroupLabel>By Platform</ListGroupLabel>
          <Grid columns={4} gap="regular">
            {groups.platform.map(template => (
              <StoryboardTemplateCard
                {...template}
                selected={selection.includes(template.id)}
                key={template.id}
                onClick={() => toggleSelection(template.id)}
              />
            ))}
          </Grid>

          <ListGroupLabel>By Creative</ListGroupLabel>
          <Grid columns={4} gap="regular">
            {groups.creative.map(template => (
              <StoryboardTemplateCard
                {...template}
                selected={selection.includes(template.id)}
                key={template.id}
                onClick={() => toggleSelection(template.id)}
              />
            ))}
          </Grid>

          <ListGroupLabel>By Audience</ListGroupLabel>
          <Grid columns={4} gap="regular">
            {groups.audience.map(template => (
              <StoryboardTemplateCard
                {...template}
                selected={selection.includes(template.id)}
                key={template.id}
                onClick={() => toggleSelection(template.id)}
              />
            ))}
          </Grid>
        </Fragment>
      }
      actions={
        <Fragment>
          <CloseDialogButton />
          <div
            key={selection.length}
            style={{
              animation: selection.length > 0 ? "0.2s panoramic-pop" : "none",
              color: color.STEEL,
              fontSize: "1.2rem",
              lineHeight: "2rem",
            }}>
            {selection.length} storyboards selected
          </div>
          <Button
            autoFocus
            onClick={() => {
              globalState.addStoryboards({
                projectId: props.projectId,
                storyboards: templates.filter(template =>
                  selection.includes(template.id),
                ),
              });

              dialogs.closeDialog();
            }}
            theme="blue">
            Add selected
          </Button>
        </Fragment>
      }
    />
  );
}
