import React from "react";
import { color } from "./stylesheet";

interface TitleProps {
  children: string;
}

export default function Title(props: TitleProps) {
  return (
    <div
      style={{
        fontWeight: 700,
        fontSize: "2.5rem",
        lineHeight: "3rem",
        letterSpacing: "-0.01em",
        color: color.ALMOST_BLACK,
      }}>
      {props.children}
    </div>
  );
}
