import React from "react";
import { LinkButton } from "./button";
import { path, link } from "./paths";
import { ProjectTopNav, ProjectSideNav } from "./project-nav";
import { RouteComponentProps, Switch, Route } from "react-router-dom";
import { useGlobalState } from "./state-manager";
import NotFoundView from "./not-found-view";
import ProjectCollaboratorsView from "./project-collaborators-view";
import ProjectContextSetter from "./project-context-setter"
import ProjectDataSourcesView from "./project-data-sources-view";
import ProjectHomeView from "./project-home-view";
import ProjectSettingsView from "./project-settings";
import ProjectStoryboardsView from "./project-storyboards-view";
import SidebarLayout from "./sidebar-layout";
import UnrecognisedRouteView from "./unrecognised-route-view";

type ProjectViewProps = RouteComponentProps<{ projectId: string }>;

export default function ProjectView(props: ProjectViewProps) {
  const globalState = useGlobalState();

  const projects = globalState.getAllProjects();
  const project = globalState.getProject(props.match.params.projectId);

  if (!project) {
    return (
      <NotFoundView
        title="Project not found"
        instructions="The project might have been deleted, or you no longer have the rights to view it."
        actions={
          <LinkButton to={link.toRootProjects()} label="Back to all projects" />
        }
      />
    );
  }

  // prettier-ignore
  return (
    <ProjectContextSetter project={project}>
      <SidebarLayout
        renderTopNav={() => <ProjectTopNav {...project} />}
        renderSideNav={() => <ProjectSideNav {...project} projects={projects} />}>
        <Switch>
          <Route exact path={path.projectRoot} render={() => <ProjectHomeView {...project} />}/>
          <Route exact path={path.projectStoryboards} render={() => <ProjectStoryboardsView {...project} />}/>
          <Route exact path={path.projectCollaborators} render={() => <ProjectCollaboratorsView {...project} />}/>
          <Route exact path={path.projectDataSources} render={() => <ProjectDataSourcesView {...project} />}/>
          <Route exact path={path.projectSettings} render={() => <ProjectSettingsView {...project} />}/>
          <Route component={UnrecognisedRouteView}/>
        </Switch>
      </SidebarLayout>
    </ProjectContextSetter>
  );
}
