import { Project, Conversation, Storyboard } from "./entity-types";

export function matchActive(item: Project | Conversation) {
  return item.status === "active";
}

export function matchArchived(item: Project) {
  return item.status === "archived";
}

export function matchResolved(item: Conversation) {
  return item.status === "resolved";
}

export function matchStarred(item: Storyboard) {
  return item.starred;
}