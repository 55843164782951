import React from "react";
import { color } from "./stylesheet";
import { pick } from "./utils";

interface ProjectPictureProps {
  size?: "small" | "medium";
  pictureUrl: string;
}

export default function ProjectPicture(props: ProjectPictureProps) {
  const size = pick(props.size || "medium", {
    medium: "2.5rem",
    small: "2rem",
  });

  return (
    <div
      style={{
        width: size,
        height: size,
        flexShrink: 0,
        backgroundColor: color.VERY_LIGHT_GRAY,
        backgroundImage: `url(${props.pictureUrl})`,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        borderRadius: "0.25rem",
        marginRight: "1rem",
        boxShadow: "inset 0 0 0 1px rgba(0,0,0,0.1)"
      }}
    />
  );
}
