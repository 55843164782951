import React, { useState } from "react";
import { CloseDialogButton, CreateButton } from "./button";
import { link } from "./paths";
import { Route } from "react-router-dom";
import { useGlobalState } from "./state-manager";
import ActionsArea from "./actions-area";
import Dialog from "./dialog";
import id from "shortid";
import TextInput from "./text-input";

export default function CreateProjectDialog() {
  const [state, setState] = useState("");
  const globalState = useGlobalState();

  return (
    <Route>
      {({ history }) => (
        <Dialog title="Create Project">
          <TextInput
            autoFocus
            label="Name"
            placeholder="My project"
            value={state}
            onChange={event => setState(event.target.value)}
          />
          <TextInput
            label="Collaborators"
            placeholder="User, team, or email address"
          />
          <ActionsArea>
            <CloseDialogButton />
            <CreateButton
              onClick={() => {
                const projectId = id.generate();
                globalState.createProject({ name: state, id: projectId });
                history.push(link.toProjectRoot({ projectId }));
              }}
            />
          </ActionsArea>
        </Dialog>
      )}
    </Route>
  );
}
