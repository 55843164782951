import React, { ReactNode } from "react";
import { pick } from "./utils";

export type GridGap = "hairline" | "small" | "regular" | "none";

export interface GridProps {
  columns: number;
  gap: GridGap;

  /**
   * Elements provided to this prop will be rendered as CSS grid items
   * */
  children: ReactNode;
}

export default function Grid(props: GridProps) {
  const gap = pick(props.gap, {
    hairline: "1px",
    small: "1rem",
    regular: "2rem",
    none: "0",
  });

  return (
    <div
      style={{
        display: "grid",
        gridTemplateColumns: `repeat(${props.columns}, minmax(1px, 1fr))`,
        gridColumnGap: gap,
        gridRowGap: gap,
      }}>
      {props.children}
    </div>
  );
}
