import React from "react";
import { CloseDialogButton, CreateButton } from "./button";
import ActionsArea from "./actions-area";
import Dialog from "./dialog";
import TextInput from "./text-input";

export default function CreateTeamDialog() {
  return (
    <Dialog title="Create Team">
      <TextInput autoFocus label="Name" placeholder="Creative Team"/>
      <ActionsArea>
        <CloseDialogButton/>
        <CreateButton />
      </ActionsArea>
    </Dialog>
  );
}
