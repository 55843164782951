import React, { Fragment } from "react";
import { color } from "./stylesheet";
import { link } from "./paths";
import { LinkButton } from "./button";
import { RouteComponentProps } from "react-router-dom";
import { Storyboard } from "./entity-types";
import { useGlobalState } from "./state-manager";
import { useViewportSize } from "./viewport-observer";
import BlankSlate from "./blank-slate";
import Chart from "./chart";
import DataSourceProviderCard from "./data-source-provider-card";
import NotFoundView from "./not-found-view";
import StoryboardNav from "./storyboard-nav";
import Title from "./title";

/** Checks whether all required data sources are connected */
function allRequiredConnected(storyboard: Storyboard) {
  return storyboard.connections
    .filter(connection => connection.required)
    .every(
      connection =>
        !!storyboard.project.dataSources.find(
          dataSource => dataSource.provider === connection.provider,
        ),
    );
}

/** Checks whether all required data sources are intaking */
function allRequiredIntaking(storyboard: Storyboard) {
  return storyboard.connections
    .filter(connection => connection.required)
    .every(connection => {
      const dataSource = storyboard.project.dataSources.find(
        dataSource => dataSource.provider === connection.provider,
      );
      return !!dataSource && dataSource.status === "intaking";
    });
}

type StoryboardViewProps = RouteComponentProps<{ storyboardId: string }>;

export default function StoryboardView(props: StoryboardViewProps) {
  const globalState = useGlobalState();
  const viewport = useViewportSize();

  const storyboard = globalState.getStoryboard(props.match.params.storyboardId);

  if (!storyboard) {
    return (
      <NotFoundView
        title="Storyboard not found"
        instructions="The storyboard might have been deleted, or you no longer have the rights to view it."
        actions={
          <LinkButton
            theme="border"
            to={link.toRootProjects()}
            label="Back to all projects"
          />
        }
      />
    );
  }

  const requiredConnections = storyboard.connections.filter(
    connection => connection.required,
  );

  return (
    <div style={{ position: "relative" }}>
      <StoryboardNav
        project={storyboard.project}
        storyboard={storyboard}
        storyboards={storyboard.project.storyboards}
      />
      <main
        style={{
          margin: "0 auto 4.5rem",
          maxWidth: "98rem",
          padding: viewport.isNarrowPlus ? "2rem" : "0",
        }}>
        {viewport.isMediumPlus && (
          <div style={{ margin: "1.5rem 0 4.5rem", maxWidth: "48rem" }}>
            <div
              style={{
                height: "1.5rem",
                borderRadius: "0.75rem",
                width: "6rem",
                marginBottom: "1rem",
                background: storyboard.color,
              }}
            />
            <Title>{storyboard.name}</Title>
            <div
              style={{
                marginTop: "1rem",
                fontSize: "1.5rem",
                lineHeight: "2rem",
                color: color.STEEL,
              }}>
              {storyboard.description}
            </div>
          </div>
        )}
        {allRequiredIntaking(storyboard) ? (
          <Fragment>
            <Chart type="line" />
            <Chart type="area" />
            <Chart type="bar" />
            <Chart type="scatter" />
          </Fragment>
        ) : allRequiredConnected(storyboard) ? (
          <BlankSlate
            title="Initial data intaking in progress..."
            instructions="Data for this storyboard is currently being synced. You will be notified once it’s ready."
          />
        ) : (
          <BlankSlate
            title="Missing required data sources..."
            instructions="This storyboard is missing one or more required data sources. Connect them to explore this storyboard."
            actions={
              <div
                style={{
                  display: "grid",
                  gridColumnGap: "2rem",
                  gridRowGap: "2rem",
                  gridTemplateColumns: `repeat(${requiredConnections.length > 3 ? 3 : requiredConnections.length}, 18rem)`,
                }}>
                {requiredConnections.map(connection => (
                  <DataSourceProviderCard
                    key={connection.provider}
                    projectId={storyboard.project.id}
                    provider={connection.provider}
                  />
                ))}
              </div>
            }
          />
        )}
      </main>
    </div>
  );
}
