import React, { ReactNode } from "react";
import { Card } from "./card";
import { useViewportSize } from "./viewport-observer";
import SectionHeader from "./section-header";

interface ContentSectionProps {
  title: string;
  rim?: "none" | "danger";
  children: ReactNode;
}

export default function ContentSection(props: ContentSectionProps) {
  const viewport = useViewportSize();

  return (
    <Card padding={viewport.isNarrowPlus ? "huge" : "regular"} rim={props.rim}>
      <SectionHeader title={props.title} />
      {props.children}
    </Card>
  );
}
