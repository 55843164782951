import React from "react";
import { Button, LinkButton } from "./button";
import { Card } from "./card";
import { color } from "./stylesheet";
import { Conversation, Comment, CommentAction } from "./entity-types";
import { link } from "./paths";
import { prototypeWarning } from "./utils";
import { useDialogs } from "./dialog-manager";
import { useGlobalState } from "./state-manager";
import { useHover } from "./custom-hooks";
import { useProjectContext } from "./project-context-setter";
import AddDataSourcesDialog from "./add-data-sources-dialog";
import AddStoryboardsDialog from "./add-storyboards-dialog";
import Icon from "./icon";
import IconButton from "./icon-button";
import IconRow from "./icon-row";
import moment from "moment";
import UserPicture from "./user-picture";

function CommentActionButton(props: { action: CommentAction }): JSX.Element {
  const project = useProjectContext();
  const dialogs = useDialogs();

  switch (props.action) {
    case "CONNECT_DATA_SOURCES": {
      if (project.dataSources.length > 0) {
        return (
          <LinkButton
            label="View data sources"
            theme="border"
            to={link.toProjectDataSources({ projectId: project.id })}
          />
        );
      }

      return (
        <Button
          label="Connect data sources"
          theme="blue"
          onClick={() =>
            dialogs.openDialog(<AddDataSourcesDialog projectId={project.id} />)
          }
        />
      );
    }

    case "ADD_STORYBOARDS": {
      if (project.storyboards.length > 0) {
        return (
          <LinkButton
            label="View storyboards"
            theme="border"
            to={link.toProjectStoryboards({ projectId: project.id })}
          />
        );
      }

      return (
        <Button
          label="Add storyboards"
          theme="blue"
          onClick={() =>
            dialogs.openDialog(<AddStoryboardsDialog projectId={project.id} />)
          }
        />
      );
    }
  }
}

function ConversationComment(props: Comment) {
  return (
    <div
      style={{
        display: "flex",
        padding: "0 1.5rem",
        alignItems: "start",
        marginBottom: "1rem",
      }}>
      <div
        style={{
          height: "4rem",
          width: "3.5rem",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexShrink: 0,
        }}>
        <UserPicture pictureUrl={props.author.avatarUrl} size="large" />
      </div>
      <div style={{ marginLeft: "1.5rem" }}>
        <div
          style={{
            fontSize: "1.25rem",
            lineHeight: "2rem",
            color: color.BLUE_GRAY,
          }}>
          {props.author.name} · {moment(props.created).fromNow()}
        </div>
        <div
          style={{
            fontSize: "1.25rem",
            lineHeight: "2rem",
            color: color.ALMOST_BLACK,
          }}>
          {props.text}
        </div>
        {props.action && (
          <div style={{ marginTop: "1rem" }}>
            <CommentActionButton action={props.action} />
          </div>
        )}
      </div>
    </div>
  );
}

function AddCommentButton() {
  const { hovered, ...handlers } = useHover();

  return (
    <button {...handlers} onClick={prototypeWarning} style={{ width: "100%" }}>
      <div
        style={{
          backgroundColor: hovered ? color.LIGHT_GRAY : color.VERY_LIGHT_GRAY,
          cursor: "pointer",
          padding: "1.5rem",
          display: "flex",
          alignItems: "center",
          boxShadow: "0 -1px 0 0 #e6e6e6",
          transition: "background-color 0.1s",
        }}>
        <div style={{ padding: "0 0.75rem" }}>
          <Icon name="comment" />
        </div>
        <div
          style={{
            fontSize: "1.25rem",
            lineHeight: "2rem",
            color: color.BLUE_GRAY,
            marginLeft: "1.5rem",
          }}>
          Add comment
        </div>
      </div>
    </button>
  );
}

export default function ConversationCard(props: Conversation) {
  const globalState = useGlobalState();
  const project = useProjectContext();

  return (
    <Card>
      <div
        style={{
          display: "flex",
          padding: "1.5rem",
          alignItems: "start",
        }}>
        <div
          style={{
            width: "3.5rem",
            height: "3.5rem",
            backgroundColor: "#e2e2e2",
            backgroundImage: props.thumbUrl && `url(${props.thumbUrl})`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            borderRadius: "0.25rem",
          }}
        />
        <div style={{ marginLeft: "1.5rem" }}>
          <div
            style={{
              fontSize: "1.5rem",
              lineHeight: "2rem",
              color: color.ALMOST_BLACK,
            }}>
            {props.name}
          </div>
          <div
            style={{
              fontSize: "1.25rem",
              lineHeight: "1.5rem",
              color: color.BLUE_GRAY,
            }}>
            {moment(props.created).fromNow()}
          </div>
        </div>
        <IconRow>
          <IconButton
            icon="resolve"
            onClick={() => {
              globalState.toggleConversationResolution({
                conversationId: props.id,
                projectId: project.id,
              });
            }}
          />
          <IconButton icon="more" onClick={prototypeWarning} />
        </IconRow>
      </div>
      <div style={{ paddingBottom: "0.5rem" }}>
        {props.comments.map(comment => (
          <ConversationComment key={comment.id} {...comment} />
        ))}
      </div>
      <AddCommentButton />
    </Card>
  );
}
