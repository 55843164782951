import React from "react";
import { color } from "./stylesheet";
import { pick } from "./utils";

export type IconName =
  | "add"
  | "back"
  | "bar-chart"
  | "cog"
  | "collaborator"
  | "comment"
  | "company"
  | "empty-star"
  | "git-merge"
  | "home"
  | "menu"
  | "more"
  | "open"
  | "projects"
  | "resolve"
  | "search"
  | "star"
  | "team"
  | "user";

export type IconColor = keyof typeof colorMap;

const colorMap = {
  yellow: color.YELLOW,
  blue: color.BLUE,
  "light-blue-gray": color.LIGHT_BLUE_GRAY,
};

function renderIconSvg(name: IconName, fill: string): JSX.Element {
  switch (name) {
    case "add":
      return (
        <svg width="1em" height="1em" viewBox="0 0 24 24">
          <path
            d="M17 11h-4V7c0-.55-.45-1-1-1s-1 .45-1 1v4H7c-.55 0-1 .45-1 1s.45 1 1 1h4v4c0 .55.45 1 1 1s1-.45 1-1v-4h4c.55 0 1-.45 1-1s-.45-1-1-1z"
            fill={fill}
            fillRule="evenodd"
          />
        </svg>
      );
    case "home":
      return (
        <svg width="1em" height="1em" viewBox="0 0 24 24">
          <path
            d="M6 14v5c0 .55.45 1 1 1h3v-5h4v5h3c.55 0 1-.45 1-1v-5l-6-6-6 6zm13.71-2.71L18 9.59V6c0-.55-.45-1-1-1s-1 .45-1 1v1.59l-3.29-3.3A.997.997 0 0 0 12 4c-.28 0-.53.11-.71.29l-7 7a1.003 1.003 0 0 0 1.42 1.42L12 6.41l6.29 6.29c.18.19.43.3.71.3a1.003 1.003 0 0 0 .71-1.71z"
            fill={fill}
            fillRule="nonzero"
          />
        </svg>
      );
    case "cog":
      return (
        <svg width="1em" height="1em" viewBox="0 0 24 24">
          <path
            d="M19.19 10.4h-1.848c-.11-.37-.27-.71-.45-1.04L18.252 8c.309-.31.309-.82 0-1.13l-1.13-1.13a.802.802 0 0 0-1.13 0L14.634 7.1c-.33-.17-.67-.33-1.039-.44V4.8c0-.44-.36-.8-.8-.8h-1.598c-.44 0-.8.36-.8.8v1.86c-.39.12-.75.28-1.1.47l-1.298-1.3c-.3-.3-.79-.3-1.09 0l-1.08 1.09c-.299.3-.299.79 0 1.09l1.3 1.3c-.2.34-.36.7-.48 1.09H4.8c-.44 0-.8.36-.8.8v1.6c0 .44.36.8.8.8h1.848c.11.37.27.71.45 1.04L5.738 16c-.309.31-.309.82 0 1.13l1.13 1.13c.31.31.82.31 1.13 0l1.359-1.36c.33.18.67.33 1.039.44v1.86c0 .44.36.8.8.8h1.599c.44 0 .799-.36.799-.8v-1.86c.39-.12.75-.28 1.1-.47l1.299 1.3c.3.3.789.3 1.089 0l1.09-1.09c.299-.3.299-.79 0-1.09l-1.3-1.3c.19-.35.36-.71.48-1.1H19.2c.44 0 .8-.36.8-.8v-1.6a.816.816 0 0 0-.81-.79zM11.996 15a2.995 2.995 0 0 1-2.998-3c0-1.66 1.339-3 2.998-3a2.995 2.995 0 0 1 2.998 3c0 1.66-1.339 3-2.998 3z"
            fill={fill}
            fillRule="nonzero"
          />
        </svg>
      );
    case "collaborator":
      return (
        <svg width="1em" height="1em" viewBox="0 0 24 24">
          <path
            d="M12 4a7.998 7.998 0 0 0-7.96 8.78c.01.09.03.18.04.28.02.15.04.31.07.47.02.11.05.22.08.34.03.13.06.26.1.38.04.12.08.25.12.37.04.11.08.21.12.32a6.583 6.583 0 0 0 .3.65c.07.14.14.27.22.4.04.07.08.13.12.2l.27.42.1.13a7.973 7.973 0 0 0 3.83 2.82c.03.01.05.02.07.03.37.12.75.22 1.14.29l.2.03c.39.06.79.1 1.2.1.41 0 .81-.04 1.2-.1l.2-.03c.39-.07.77-.16 1.14-.29.03-.01.05-.02.07-.03a8.037 8.037 0 0 0 3.83-2.82c.03-.04.06-.08.09-.13.1-.14.19-.28.28-.42.04-.07.08-.13.12-.2.08-.13.15-.27.22-.41.04-.08.08-.17.12-.26.06-.13.11-.26.17-.39.04-.1.08-.21.12-.32.04-.12.08-.24.12-.37.04-.13.07-.25.1-.38.03-.11.06-.22.08-.34.03-.16.05-.31.07-.47.01-.09.03-.18.04-.28.02-.26.04-.51.04-.78C20 7.58 16.42 4 12 4zm0 14.4c-1.98 0-3.75-.9-4.92-2.31.67-.36 1.49-.66 2.14-.95 1.16-.52 1.04-.84 1.08-1.27.01-.06.01-.11.01-.17-.41-.36-.74-.86-.96-1.44v-.01c0-.01-.01-.02-.01-.02-.05-.13-.09-.26-.12-.39-.28-.05-.44-.35-.5-.63-.06-.11-.18-.38-.15-.69.04-.41.2-.59.38-.67v-.06c0-.51.05-1.24.14-1.72.02-.13.05-.26.09-.39.17-.59.53-1.12 1.01-1.49.49-.38 1.19-.59 1.82-.59.62 0 1.32.2 1.82.59.48.37.84.9 1.01 1.49.04.13.07.26.09.4.09.48.14 1.21.14 1.72v.07c.18.08.33.26.37.66.03.31-.1.58-.16.69-.06.27-.21.57-.48.62-.03.13-.07.26-.12.38 0 .01-.01.04-.01.04-.21.57-.54 1.06-.94 1.42 0 .06.01.13.01.19.04.43-.12.75 1.05 1.27.65.29 1.47.6 2.14.95A6.415 6.415 0 0 1 12 18.4z"
            fill={fill}
            fillRule="nonzero"
          />
        </svg>
      );
    case "git-merge":
      return (
        <svg width="1em" height="1em" viewBox="0 0 24 24">
          <path
            d="M16 10c-1.3 0-2.4.84-2.82 2H13c-1.62 0-3-.96-3.63-2.34C10.33 9.16 11 8.16 11 7c0-1.66-1.34-3-3-3S5 5.34 5 7c0 1.3.84 2.4 2 2.81v4.37C5.84 14.6 5 15.7 5 17c0 1.66 1.34 3 3 3s3-1.34 3-3c0-1.3-.84-2.4-2-2.82v-1.75A5.89 5.89 0 0 0 13 14h.18A2.996 2.996 0 0 0 19 13c0-1.66-1.34-3-3-3zm-8 8c-.55 0-1-.45-1-1s.45-1 1-1 1 .45 1 1-.45 1-1 1zM8 8c-.55 0-1-.45-1-1s.45-1 1-1 1 .45 1 1-.45 1-1 1zm8 6c-.55 0-1-.45-1-1s.45-1 1-1 1 .45 1 1-.45 1-1 1z"
            fill={fill}
            fillRule="nonzero"
          />
        </svg>
      );
    case "bar-chart":
      return (
        <svg width="1em" height="1em" viewBox="0 0 24 24">
          <path
            d="M12 16h1c.55 0 1-.45 1-1V6c0-.55-.45-1-1-1h-1c-.55 0-1 .45-1 1v9c0 .55.45 1 1 1zm5 0h1c.55 0 1-.45 1-1V9c0-.55-.45-1-1-1h-1c-.55 0-1 .45-1 1v6c0 .55.45 1 1 1zm2 1H6c-.55 0-1 .45-1 1s.45 1 1 1h13c.55 0 1-.45 1-1s-.45-1-1-1zM7 16h1c.55 0 1-.45 1-1v-4c0-.55-.45-1-1-1H7c-.55 0-1 .45-1 1v4c0 .55.45 1 1 1z"
            fill={fill}
            fillRule="nonzero"
          />
        </svg>
      );
    case "projects":
      return (
        <svg width="1em" height="1em" viewBox="0 0 24 24">
          <path
            d="M18 7c0-.55-.45-1-1-1H7c-.55 0-1 .45-1 1v1h12V7zm-2-3H8c-.55 0-1 .45-1 1h10c0-.55-.45-1-1-1zm3 5H5c-.55 0-1 .45-1 1v9c0 .55.45 1 1 1h14c.55 0 1-.45 1-1v-9c0-.55-.45-1-1-1zm-3 6c0 .55-.45 1-1 1H9c-.55 0-1-.45-1-1v-2h1v2h6v-2h1v2z"
            fill={fill}
            fillRule="nonzero"
          />
        </svg>
      );
    case "comment":
      return (
        <svg width="1em" height="1em" viewBox="0 0 24 24">
          <path
            d="M18.5 5h-13c-.55 0-1 .45-1 1v9c0 .55.45 1 1 1h2v3a1.003 1.003 0 0 0 1.71.71l3.7-3.71h5.59c.55 0 1-.45 1-1V6c0-.55-.45-1-1-1zM8 12c-.83 0-1.5-.67-1.5-1.5S7.17 9 8 9s1.5.67 1.5 1.5S8.83 12 8 12zm4 0c-.83 0-1.5-.67-1.5-1.5S11.17 9 12 9s1.5.67 1.5 1.5S12.83 12 12 12zm4 0c-.83 0-1.5-.67-1.5-1.5S15.17 9 16 9s1.5.67 1.5 1.5S16.83 12 16 12z"
            fill={fill}
            fillRule="nonzero"
          />
        </svg>
      );
    case "search":
      return (
        <svg width="1em" height="1em" viewBox="0 0 24 24">
          <path
            d="M19.587 16.6l-2.503-2.513a6.506 6.506 0 0 0 1.041-3.524A6.558 6.558 0 0 0 11.562 4 6.558 6.558 0 0 0 5 10.563a6.558 6.558 0 0 0 6.563 6.562 6.537 6.537 0 0 0 3.524-1.04l2.513 2.502a1.404 1.404 0 1 0 1.987-1.987zm-8.024-1.35a4.69 4.69 0 0 1-4.688-4.688 4.69 4.69 0 0 1 4.688-4.687 4.69 4.69 0 0 1 4.687 4.688 4.69 4.69 0 0 1-4.688 4.687z"
            fill={fill}
            fillRule="evenodd"
          />
        </svg>
      );
    case "more":
      return (
        <svg width="1em" height="1em" viewBox="0 0 24 24">
          <path
            d="M10.089 5.933c0-1.117.895-2.022 2-2.022 1.104 0 2 .905 2 2.022a2.011 2.011 0 0 1-2 2.023c-1.105 0-2-.906-2-2.023zm0 12.134c0-1.117.895-2.023 2-2.023 1.104 0 2 .906 2 2.023a2.011 2.011 0 0 1-2 2.022c-1.105 0-2-.905-2-2.022zm0-6.067c0-1.117.895-2.022 2-2.022 1.104 0 2 .905 2 2.022a2.011 2.011 0 0 1-2 2.022c-1.105 0-2-.905-2-2.022z"
            fill={fill}
            fillRule="evenodd"
          />
        </svg>
      );
    case "open":
      return (
        <svg width="1em" height="1em" viewBox="0 0 24 24">
          <path
            d="M16 17H7V8h4.76l2-2H6c-.55 0-1 .45-1 1v11c0 .55.45 1 1 1h11c.55 0 1-.45 1-1v-7.75l-2 2V17zm4-14h-5c-.55 0-1 .45-1 1s.45 1 1 1h2.59l-5.29 5.29a1 1 0 0 0-.3.71 1.003 1.003 0 0 0 1.71.71L19 6.42v2.59c0 .55.45 1 1 1s1-.45 1-1v-5C21 3.45 20.55 3 20 3z"
            fill={fill}
            fillRule="nonzero"
          />
        </svg>
      );
    case "back":
      return (
        <svg width="1em" height="1em" viewBox="0 0 24 24">
          <path
            d="M15.483 17.687a1.2 1.2 0 1 1-1.714 1.68L6.32 11.762 13.77 4.16a1.2 1.2 0 0 1 1.714 1.68L9.68 11.763l5.803 5.924z"
            fill={fill}
            fillRule="nonzero"
          />
        </svg>
      );
    case "resolve":
      return (
        <svg width="1em" height="1em" viewBox="0 0 24 24">
          <path
            d="M19.184 6c-.34 0-.643.132-.861.348l-8.845 8.76-3.992-3.96a1.216 1.216 0 0 0-.862-.348A1.21 1.21 0 0 0 3.411 12c0 .336.134.636.352.852l4.853 4.8c.219.216.522.348.862.348.34 0 .643-.132.861-.348l9.707-9.6a1.19 1.19 0 0 0 .352-.852c0-.66-.546-1.2-1.214-1.2z"
            fill={fill}
            fillRule="evenodd"
          />
        </svg>
      );
    case "company":
      return (
        <svg width="1em" height="1em" viewBox="0 0 24 24">
          <path
            d="M19 9h-3V5c0-.55-.45-1-1-1H5c-.55 0-1 .45-1 1v14c0 .55.45 1 1 1h3v-4h4v4h7c.55 0 1-.45 1-1v-9c0-.55-.45-1-1-1zM9 14H6v-3h3v3zm0-5H6V6h3v3zm5 5h-3v-3h3v3zm0-5h-3V6h3v3zm4 9h-2v-2h2v2zm0-4h-2v-3h2v3z"
            fill={fill}
            fillRule="nonzero"
          />
        </svg>
      );
    case "empty-star":
      return (
        <svg width="1em" height="1em" viewBox="0 0 24 24">
          <path
            d="M20 10.11l-5.53-.84L12 4 9.53 9.27 4 10.11l4 4.1L7.06 20 12 17.27 16.94 20 16 14.21l4-4.1zM8.91 17.2l.59-3.62L7 11.02l3.45-.53L12 7.2l1.55 3.29 3.45.53-2.5 2.56.59 3.62L12 15.49 8.91 17.2z"
            fill={fill}
            fillRule="nonzero"
          />
        </svg>
      );
    case "star":
      return (
        <svg width="1em" height="1em" viewBox="0 0 24 24">
          <path
            d="M20 10.11l-5.53-.84L12 4 9.53 9.27 4 10.11l4 4.1L7.06 20 12 17.27 16.94 20 16 14.21z"
            fill={fill}
            fillRule="nonzero"
          />
        </svg>
      );
    case "team":
      return (
        <svg width="1em" height="1em" viewBox="0 0 24 24">
          <path
            d="M17.673 18.02c-.05-.24-.14-.5-.25-.76-.361-.86-1.122-1.33-2.694-2-.14-.06-.59-.25-.6-.25-.21-.09-.36-.15-.501-.22.02-.1.02-.2.03-.31 0-.04.01-.08.01-.13-.07-.06-.13-.12-.19-.19.22-.32.4-.67.54-1.05.02-.06.02-.06.03-.1.29-.23.481-.57.591-.96.16-.33.25-.73.21-1.16-.03-.4-.16-.76-.37-1.03-.02-.53-.07-1.13-.15-1.54-.01-.06-.02-.12-.03-.19.23-.06.48-.09.72-.09.491 0 1.052.16 1.442.46.38.29.671.7.801 1.17.03.1.05.21.07.31.07.37.11.94.11 1.33v.05c.14.06.27.21.29.51.02.25-.07.45-.13.54-.05.21-.16.44-.38.48a1.709 1.709 0 0 1-.1.33c-.17.44-.43.83-.75 1.11v.14c.03.35-.09.59.83 1 .931.41 2.323.84 2.603 1.5.29.66.17 1.04.17 1.04h-2.302v.01zm-1.172-.38c.37.86.22 1.36.22 1.36H4.028s-.14-.5.22-1.36c.36-.86 2.133-1.43 3.314-1.96 1.171-.54 1.051-.86 1.091-1.3 0-.05.01-.11.01-.17-.41-.35-.75-.86-.97-1.45v-.01s-.01-.01-.01-.02c-.041-.12-.091-.26-.121-.39-.28-.05-.44-.36-.5-.64-.06-.12-.19-.39-.16-.71.04-.41.21-.6.39-.68v-.06c0-.51.05-1.26.14-1.74A2.984 2.984 0 0 1 8.542 6.6c.5-.39 1.211-.6 1.842-.6s1.342.21 1.842.6c.48.38.851.91 1.022 1.52.04.13.07.27.09.4.09.48.14 1.22.14 1.73v.07c.18.08.34.27.37.67.03.32-.09.59-.16.71-.06.28-.21.58-.48.63-.03.13-.07.26-.12.39 0 .01-.01.04-.01.04-.22.58-.551 1.08-.952 1.45v.18c.04.45-.12.77 1.061 1.3 1.182.53 2.954 1.09 3.314 1.95z"
            fill={fill}
            fillRule="nonzero"
          />
        </svg>
      );
    case "user":
      return (
        <svg width="1em" height="1em" viewBox="0 0 24 24">
          <path
            d="M19.69 18.34c-.461-1.051-2.683-1.752-4.164-2.402-1.48-.65-1.28-1.05-1.33-1.591-.01-.07-.01-.15-.01-.23.51-.45.92-1.071 1.19-1.782 0 0 .01-.04.02-.05.06-.15.11-.32.15-.48.34-.07.54-.44.61-.78.081-.14.231-.48.201-.87-.05-.501-.25-.731-.47-.821v-.09c0-.631-.06-1.552-.17-2.152a3.674 3.674 0 0 0-1.39-2.362c-.642-.47-1.532-.73-2.323-.73-.79 0-1.68.25-2.311.73-.61.47-1.061 1.131-1.281 1.862-.05.17-.09.33-.11.5-.12.6-.17 1.511-.17 2.151v.08c-.24.09-.45.32-.5.831-.03.38.13.72.2.86.08.35.28.721.63.781.04.17.09.33.15.49 0 .01.01.02.01.03l.01.01c.27.721.7 1.351 1.22 1.802 0 .07-.01.14-.01.21-.05.54.1.94-1.37 1.59-1.481.651-3.703 1.352-4.163 2.402-.46 1.051-.27 1.672-.27 1.672h15.93c-.01.01.18-.61-.28-1.662z"
            fill={fill}
            fillRule="evenodd"
          />
        </svg>
      );
    case "menu":
      return (
        <svg width="1em" height="1em" viewBox="0 0 24 24">
          <path
            d="M5 8h14c.55 0 1-.45 1-1s-.45-1-1-1H5c-.55 0-1 .45-1 1s.45 1 1 1zm14 8H5c-.55 0-1 .45-1 1s.45 1 1 1h14c.55 0 1-.45 1-1s-.45-1-1-1zm0-5H5c-.55 0-1 .45-1 1s.45 1 1 1h14c.55 0 1-.45 1-1s-.45-1-1-1z"
            fill={fill}
            fillRule="nonzero"
          />
        </svg>
      );
  }
}

export interface IconProps {
  name: IconName;
  color?: IconColor;
}

export default function Icon(props: IconProps) {
  return (
    <div
      style={{
        position: "relative",
        width: "2rem",
        height: "2rem",
        fontSize: "2rem",
      }}>
      {renderIconSvg(
        props.name,
        pick(props.color || "light-blue-gray", colorMap),
      )}
    </div>
  );
}
