import React, {
  createContext,
  ReactNode,
  useState,
  Fragment,
  useRef,
  useContext,
} from "react";
import { noop } from "./utils";
import { zIndex } from "./stylesheet";

interface DialogContextType {
  /**
   * Takes provided dialog and places it into the dialog stack.
   *
   * The data is essentially "snapshotted" when the dialog is
   * opened, so if you want to keep it in sync with eg. global state,
   * pass over only IDs and select the relevant entities from the dialog
   * itself.
   */
  openDialog: (dialog: JSX.Element) => void;

  /**
   * Closes the top-most dialog in the dialog stack.
   */
  closeDialog: () => void;

  /**
   * Closes all dialogs in the dialogs stack.
   */
  closeAll: () => void;
}

const DialogContext = createContext<DialogContextType>({
  openDialog: noop,
  closeDialog: noop,
  closeAll: noop,
});

export function useDialogs() {
  return useContext(DialogContext);
}

interface OverlayProps {
  children: JSX.Element;
}

function Overlay(props: OverlayProps) {
  const context = useContext(DialogContext);
  const dialogRef = useRef<HTMLDivElement>(null);

  return (
    <div
      ref={dialogRef}
      onClick={event => {
        if (dialogRef.current === event.target) {
          context.closeDialog();
        }
      }}
      // Note that for `onKeyUp` to work, focus has to be inside
      // the dialog. This can be a gotcha, but it is essential for good
      // keyboard support anyways, so it is our job to make sure focus
      // is handled correctly.
      //
      // See this link for more info:
      // https://www.w3.org/TR/wai-aria-practices-1.1/#dialog_modal
      onKeyUp={event => {
        if (event.key === "Escape") {
          context.closeDialog();
        }
      }}
      style={{
        position: "fixed",
        display: "flex",
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        padding: "2rem",
        overflow: "auto",
        backgroundColor: "rgba(0,0,0,0.4)",
        animation: "0.2s panoramic-fade-in",
        animationFillMode: "both",
        zIndex: zIndex.DIALOG,
      }}>
      {props.children}
    </div>
  );
}

interface DialogManagerProps {
  children: ReactNode;
}

/**
 * Contains the dialog stack state, and provides methods to nested components
 * so that they can open/close dialogs from inside the app hierarchy easily.
 * 
 * All dialogs will be rendered underneath the provided children.
 */

export default function DialogManager(props: DialogManagerProps) {
  const [dialogStack, setState] = useState<JSX.Element[]>([]);

  return (
    <DialogContext.Provider
      value={{
        openDialog(dialog) {
          setState(dialogs => [...dialogs, dialog]);
        },
        closeDialog() {
          setState(dialogs => {
            const newDialogs = dialogs.slice();
            newDialogs.pop();

            return newDialogs;
          });
        },
        closeAll() {
          setState([]);
        },
      }}>
      {props.children}

      {dialogStack.length > 0 && (
        <Fragment>
          {dialogStack.map((dialog, i) => (
            <Overlay key={i}>{dialog}</Overlay>
          ))}
        </Fragment>
      )}
    </DialogContext.Provider>
  );
}
