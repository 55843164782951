import React from "react";
import { Button } from "./button";
import { color, position } from "./stylesheet";
import { useDialogs } from "./dialog-manager";
import { useViewportSize } from "./viewport-observer";
import ActionsArea from "./actions-area";
import Dialog from "./dialog";
import Paragraph from "./paragraph";
import Title from "./title";

interface LargeDialogProps {
  title: string;
  description: string;
  body: JSX.Element;
  actions: JSX.Element;
  image?: "data-sources";
}

export default function LargeDialog(props: LargeDialogProps) {
  const viewport = useViewportSize();
  const dialogs = useDialogs();

  if (!viewport.isMediumPlus) {
    return (
      <Dialog title="Action not available">
        <Paragraph>
          Sorry, this action is currently only available on devices with larger
          screens.
        </Paragraph>
        <ActionsArea>
          <Button theme="border" onClick={dialogs.closeDialog}>
            Ok
          </Button>
        </ActionsArea>
      </Dialog>
    );
  }

  return (
    <div
      style={{
        position: "relative",
        minHeight: "calc(100vh - 12rem)",
        backgroundColor: color.VERY_LIGHT_GRAY,
        maxWidth: "90rem",
        backgroundImage:
          props.image === "data-sources"
            ? "url(/images/data-sources-dialog-illustration.svg)"
            : "",
        backgroundPosition: "top right",
        backgroundRepeat: "no-repeat",
        margin: "auto",
        borderRadius: "0.25rem",
        boxShadow:
          "0 1px 4px -1px rgba(0,0,0,0.30), 0 4px 24px -2px rgba(0,0,0,0.10)",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
      }}>
      <div
        style={{
          padding: viewport.isMediumPlus ? "5rem 6rem 6rem" : "1.5rem",
          animation: "0.2s panoramic-fade-in",
          animationFillMode: "both",
        }}>
        <header style={{ maxWidth: "28rem", marginBottom: "4.5rem" }}>
          <Title>{props.title}</Title>
          <div
            style={{
              marginTop: "1.5rem",
              fontSize: "1.25rem",
              lineHeight: "1.75rem",
              color: color.STEEL,
            }}>
            {props.description}
          </div>
        </header>
        {props.body}
      </div>
      <div
        style={{
          position: position.STICKY,
          bottom: "-2rem",
          display: "flex",
          padding: "1.5rem 2rem",
          boxShadow: `inset 0 1px 0 0 rgb(230, 230, 230)`,
          alignItems: "center",
          borderRadius: "0 0 0.25rem 0.25rem",
          justifyContent: "space-between",
          backgroundColor: color.VERY_LIGHT_GRAY,
        }}>
        {props.actions}
      </div>
    </div>
  );
}
