import React from "react";
import { CompanySideNav, CompanyTopNav } from "./company-nav";
import { LinkButton } from "./button";
import { path, link } from "./paths";
import { Redirect, Switch, Route, RouteComponentProps } from "react-router-dom";
import { useGlobalState } from "./state-manager";
import CompanyProjectsView from "./company-projects-view";
import CompanySettingsView from "./company-settings-view";
import CompanyTeamsView from "./company-teams-view";
import CompanyUsersView from "./company-users-view";
import NotFoundView from "./not-found-view";
import SidebarLayout from "./sidebar-layout";
import UnrecognisedRouteView from "./unrecognised-route-view";

type CompanyViewProps = RouteComponentProps<{ companyId: string }>;

export default function CompanyView(props: CompanyViewProps) {
  const globalState = useGlobalState();

  const company = globalState.getCompany(props.match.params.companyId);

  if (!company) {
    return (
      <NotFoundView
        title="Company not found"
        instructions="The company might have been deleted, or you no longer have the rights to view it."
        actions={
          <LinkButton
            theme="border"
            to={link.toRootCompanies()}
            label="Back to settings"
          />
        }
      />
    );
  }

  // prettier-ignore
  return (
    <SidebarLayout
      renderTopNav={() => <CompanyTopNav {...company} />}
      renderSideNav={() => <CompanySideNav {...company} companies={globalState.getAllCompanies()} />}>
      <Switch>
        <Redirect exact from={path.companyRoot} to={path.companySettings} />
        <Route exact path={path.companySettings} render={() => <CompanySettingsView {...company} />} />
        <Route exact path={path.companyProjects} render={() => <CompanyProjectsView {...company} />} />
        <Route exact path={path.companyUsers} render={() => <CompanyUsersView {...company} />} />
        <Route exact path={path.companyTeams} render={() => <CompanyTeamsView {...company} />} />
        <Route component={UnrecognisedRouteView} />
      </Switch>
    </SidebarLayout>
  );
}
