import React from "react";
import { Button } from "./button";
import { getProviderName } from "./entity-helpers";
import { Project } from "./entity-types";
import { useDialogs } from "./dialog-manager";
import AddDataSourcesDialog from "./add-data-sources-dialog";
import BlankSlate from "./blank-slate";
import ListView from "./list-view";
import ResponsiveButton from "./responsive-button";
import ResponsiveListItem from "./responsive-list-item";

export default function ProjectDataSourcesView(props: Project) {
  const dialogs = useDialogs();

  function openDialog() {
    dialogs.openDialog(<AddDataSourcesDialog projectId={props.id} />);
  }

  return (
    <ListView
      title="Data Sources"
      items={props.dataSources}
      actions={
        <ResponsiveButton
          onClick={openDialog}
          label="Add Data Sources"
          icon="add"
        />
      }
      renderItem={dataSource => (
        <ResponsiveListItem
          key={dataSource.id}
          to="/not-implemented"
          title={dataSource.name}
          text={`${getProviderName(dataSource.provider)} (${dataSource.token})`}
        />
      )}
      renderBlankSlate={() => (
        <BlankSlate
          illustration="data-sources"
          title="No Data Sources"
          instructions="Connect one or more data sources to get data into this project."
          actions={
            <Button onClick={openDialog} label="Add Data Source" theme="blue" />
          }
        />
      )}
    />
  );
}
