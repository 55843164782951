import React, { Fragment, useState } from "react";
import { link, path } from "./paths";
import { Link, Switch, Route } from "react-router-dom";
import { Transition, animated } from "react-spring";
import { useViewportSize } from "./viewport-observer";
import IconButton from "./icon-button";
import Label from "./label";
import NavLink from "./nav-link";
import SideNav from "./side-nav";
import TopNav from "./top-nav";
import UserPicture from "./user-picture";

function LogoLink() {
  return (
    <Link
      to="/"
      style={{
        display: "block",
        padding: "0.75rem 1rem",
        marginBottom: "2.5rem",
      }}>
      <div
        style={{
          width: "2rem",
          height: "2rem",
          fontSize: "2rem",
        }}>
        <svg width="1em" height="1em" viewBox="0 0 24 24">
          <g fill="#000" fillRule="nonzero">
            <path d="M7.524 12.661c0 5.758 2.681 10.676 7.834 10.676 5.152 0 7.833-4.918 7.833-10.676s-2.68-10.633-7.833-10.633c-5.153 0-7.834 4.875-7.834 10.633m2.263 0c0-4.37 1.717-8.742 5.57-8.742 3.897 0 5.572 4.371 5.572 8.742 0 4.413-1.675 8.785-5.571 8.785-3.854 0-5.571-4.372-5.571-8.785" />
            <path d="M.166 8.183c0 5.17 4.9 7.86 10.64 7.86 5.739 0 10.598-2.69 10.598-7.86S16.544.323 10.806.323c-5.74 0-10.64 2.69-10.64 7.86m1.885 0c0-3.866 4.356-5.59 8.755-5.59 4.356 0 8.713 1.724 8.713 5.59 0 3.91-4.357 5.59-8.713 5.59-4.399 0-8.755-1.68-8.755-5.59" />
          </g>
        </svg>
      </div>
    </Link>
  );
}

interface RootNavLinksProps {
  onClick?: () => void;
  condensed?: boolean;
}

function RootNavLinks(props: RootNavLinksProps) {
  return (
    <Fragment>
      <LogoLink />
      <NavLink
        onClick={props.onClick}
        to={link.toRootProjects()}
        icon="projects"
        label="Projects"
        condensed={props.condensed}
      />
      <NavLink
        onClick={props.onClick}
        to={link.toRootCompanies()}
        icon="cog"
        label="Settings"
        condensed={props.condensed}
      />
      <div style={{ marginTop: "auto" }}>
        <NavLink
          onClick={props.onClick}
          to={link.toRootUser()}
          icon={
            <UserPicture size="small" pictureUrl="/images/mary-reese.png" />
          }
          label="Mary Reese"
          condensed={props.condensed}
        />
      </div>
    </Fragment>
  );
}

export function RootTopNav() {
  const [state, setState] = useState(false);

  return (
    <TopNav>
      <div style={{ marginRight: "1rem" }}>
        <IconButton onClick={() => setState(true)} icon="menu" />
      </div>
      <Switch>
        <Route
          path={path.rootProjects}
          render={() => <Label>Projects</Label>}
        />
        <Route
          path={path.rootCompanies}
          render={() => <Label>Settings</Label>}
        />
        <Route path={path.rootUser} render={() => <Label>User</Label>} />
      </Switch>
      <Transition
        native
        config={{ tension: 500, friction: 60 }}
        items={state}
        from={{ opacity: 0, x: -100 }}
        enter={{ opacity: 1, x: 0 }}
        leave={{ opacity: 0, x: -100 }}>
        {open =>
          open &&
          (style => (
            <Fragment>
              <animated.div
                onClick={() => setState(false)}
                style={{
                  position: "fixed",
                  top: 0,
                  right: 0,
                  bottom: 0,
                  left: 0,
                  backgroundColor: "rgba(0,0,0,0.4)",
                  opacity: style.opacity,
                }}
              />
              <animated.div
                style={{
                  position: "fixed",
                  display: "flex",
                  top: 0,
                  left: 0,
                  bottom: 0,
                  flexDirection: "column",
                  backgroundColor: "white",
                  padding: "1rem",
                  width: "24rem",
                  boxShadow: "1px 0 3px -1px rgba(0,0,0,0.30)",

                  // Seems like the typing info is not correct here
                  transform: (style.x as any).interpolate(
                    (x: string) => `translate3d(${x}%, 0, 0)`,
                  ),
                }}>
                <RootNavLinks onClick={() => setState(false)} />
              </animated.div>
            </Fragment>
          ))
        }
      </Transition>
    </TopNav>
  );
}

interface RootSideNavProps {
  condensed?: boolean;
}

export function RootSideNav(props: RootSideNavProps) {
  const viewport = useViewportSize();
  const narrow = props.condensed || !viewport.isExtraWidePlus;

  return (
    <SideNav narrow={narrow}>
      <RootNavLinks condensed={narrow} />
    </SideNav>
  );
}
