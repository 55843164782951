import React, { ReactNode } from "react";
import { useViewportSize } from "./viewport-observer";

interface SidebarLayoutProps {
  children: ReactNode;
  renderTopNav: () => JSX.Element;
  renderSideNav: () => JSX.Element;
}

export default function SidebarLayout(props: SidebarLayoutProps) {
  const viewport = useViewportSize();

  return (
    <div
      style={{
        display: "flex",
        position: "relative",
        flexDirection: viewport.isMediumPlus ? "row" : "column",
        minHeight: "100vh",
      }}>
      {viewport.isMediumPlus ? props.renderSideNav() : props.renderTopNav()}
      <div
        style={{
          overflow: "auto",
          flexGrow: 1,
          padding:
            // prettier-ignore
            viewport.isExtraWidePlus ? "0 6rem" :
            viewport.isWidePlus ? "0 5rem" :
            viewport.isMediumPlus ? "0 4rem" :
            viewport.isNarrowPlus ? "0 2rem" :
            "0",
        }}>
        <main
          style={{
            margin:
              // prettier-ignore
              viewport.isMediumPlus ? "3.5rem auto" :
              viewport.isNarrowPlus ? "2em auto" :
              "0",
            maxWidth:
              // prettier-ignore
              viewport.isWidePlus ? "78rem" :
              viewport.isMediumPlus ? "58rem" :
              "100%",
          }}>
          {props.children}
        </main>
      </div>
    </div>
  );
}
