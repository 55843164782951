import React, { ReactNode } from "react";
import { useViewportSize } from "./viewport-observer";

export interface ToolbarProps {
  children: ReactNode;
}

export default function Toolbar(props: ToolbarProps) {
  const viewport = useViewportSize();

  return (
    <div
      style={{
        display: "flex",
        margin: viewport.isNarrowPlus ? "2rem 0" : "1rem 0",
        padding: viewport.isNarrowPlus ? "0" : "0 1.5rem",
        minHeight: "3rem",
        alignItems: "center",
      }}>
      {props.children}
    </div>
  );
}
