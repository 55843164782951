import React from "react";
import { color } from "./stylesheet";
import { link } from "./paths";
import { LinkCard } from "./card";
import { Storyboard } from "./entity-types";
import Icon from "./icon";

interface StoryboardCardProps extends Storyboard {
  onClick?: (event: React.MouseEvent) => void;
}

export default function StoryboardCard(props: StoryboardCardProps) {
  return (
    <LinkCard
      to={{
        pathname: link.toStoryboardRoot({ storyboardId: props.id }),
        state: { returnTo: location.pathname },
      }}
      padding="regular"
      onClick={props.onClick}>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <div
          style={{
            height: "1rem",
            width: "4rem",
            borderRadius: "0.5rem",
            background: props.color, // Using 'background', because the value of 'color' can include gradients etc...
            marginBottom: "0.75rem",
          }}
        />
        {props.starred && (
          <div style={{ margin: "-0.5rem" }}>
            <Icon name="star" color="yellow" />
          </div>
        )}
      </div>
      <div
        style={{
          fontSize: "1.33333rem",
          lineHeight: "1.75rem",
          marginBottom: "0.5rem",
          color: color.ALMOST_BLACK,
        }}>
        {props.name}
      </div>
      <div
        style={{
          fontSize: "1.25rem",
          lineHeight: "1.5rem",
          color: color.BLUE_GRAY,
        }}>
        {props.description}
      </div>
    </LinkCard>
  );
}
