import React from "react";
import { LinkCard } from "./card";
import { LocationDescriptor } from "history";
import { prototypeWarning } from "./utils";
import IconButton from "./icon-button";
import Label from "./label";

interface CardRowProps {
  to: LocationDescriptor;
  title: string;
  text: string;

  /**
   * The element provided to this prop will be rendered as CSS grid item
   * with its width set to 2.5rem.
   */
  icon?: JSX.Element;
}

export default function CardRow(props: CardRowProps) {
  return (
    <LinkCard to={props.to}>
      <div
        style={{
          display: "grid",
          gridTemplateColumns: props.icon
            ? "2.5rem 2fr 3fr auto"
            : "2fr 3fr auto",
          gridColumnGap: "1rem",
          alignItems: "center",
          padding: "0 1.5rem",
          height: "4.5rem"
        }}>
        {props.icon}
        <Label>{props.title}</Label>
        <Label color="blue-gray">{props.text}</Label>
        <IconButton icon="more" onClick={prototypeWarning} />
      </div>
    </LinkCard>
  );
}
