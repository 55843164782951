import React, { createContext, ReactNode, useContext } from "react";
import { Project } from "./entity-types";

export const ProjectContext = createContext<Project>({
  id: "FALLBACK_ID",
  name: "FALLBACK_NAME",
  thumbUrl: "FALLBACK_THUMB_URL",
  status: "active",
  conversations: [],
  storyboards: [],
  dataSources: [],
  collaborators: [],
});

export function useProjectContext() {
  return useContext(ProjectContext);
}

interface ProjectContextSetterProps {
  project: Project;
  children: ReactNode;
}

export default function ProjectContextSetter(props: ProjectContextSetterProps) {
  return (
    <ProjectContext.Provider value={props.project}>
      {props.children}
    </ProjectContext.Provider>
  );
}
