import React, { CSSProperties, ComponentType, ReactNode } from "react";
import { color } from "./stylesheet";
import { Link } from "react-router-dom";
import { Props, pick, prototypeWarning } from "./utils";
import { useDialogs } from "./dialog-manager";
import { useHover } from "./custom-hooks";
import PrimitiveButton from "./primitive-button";

type ThemeName = keyof typeof themeMap;

const themeMap = {
  "white": {
    color: color.DARK_STEEL,
    backgroundColor: "white",
    hoverBackgroundColor: "white",
    boxShadow: "0 1px 4px -1px rgba(0,0,0,0.30)",
    hoverBoxShadow: "0 0 3px -1px rgba(0,0,0,0.30)",
  },
  "blue": {
    color: "white",
    backgroundColor: color.BLUE,
    hoverBackgroundColor: color.DARK_BLUE,
    boxShadow: "0 1px 4px -1px rgba(0,0,0,0.30)",
    hoverBoxShadow: "0 0 3px -1px rgba(0,0,0,0.30)",
  },
  "light-blue": {
    color: color.BLUE,
    backgroundColor: color.LIGHT_BLUE,
    hoverBackgroundColor: color.LIGHT_BLUE,
    boxShadow: "none",
    hoverBoxShadow: "none",
  },
  "border": {
    color: color.DARK_STEEL,
    backgroundColor: "white",
    hoverBackgroundColor: color.VERY_LIGHT_GRAY,
    boxShadow: `inset 0 0 0 1px rgb(230, 230, 230)`,
    hoverBoxShadow: `inset 0 0 0 1px rgb(230, 230, 230)`,
  },
};

interface GetInnerPropsArgs {
  hovered: boolean;
  theme?: ThemeName;
  fill?: boolean;
  disabled?: boolean;
}

function getInnerProps(props: GetInnerPropsArgs) {
  const { theme = "white", hovered, disabled, fill, ...rest } = props;
  const buttonTheme = pick(theme, themeMap);

  const style: CSSProperties = {
    display: fill ? "block" : "inline-block",
    fontSize: "1.25rem",
    lineHeight: "2rem",
    minWidth: "8rem",
    padding: "0.5rem 1rem",
    textAlign: "center",
    cursor: "pointer",
    color: disabled ? "white" : buttonTheme.color,
    backgroundColor:
      // prettier-ignore
      disabled ? "#f2f2f2" :
      hovered ? buttonTheme.hoverBackgroundColor :
      buttonTheme.backgroundColor,
    boxShadow:
      // prettier-ignore
      props.disabled ? "none" :
      hovered ? buttonTheme.hoverBoxShadow :
      buttonTheme.boxShadow,
    borderRadius: "0.25rem",
    width: fill ? "100%" : "auto",
    transition: "box-shadow 0.2s, background-color 0.2s",
  };

  return {
    style,
    disabled,
    ...rest,
  };
}

export interface SharedButtonProps {
  theme?: ThemeName;
  label?: string;
  fill?: boolean;
  disabled?: boolean;
  children?: ReactNode;
}

function makeButtonWithComponent<Props>(Component: ComponentType<Props>) {
  return function Button(props: Props & SharedButtonProps) {
    const { hovered, ...handlers } = useHover();
    const { label, children, ...fwdProps } = props;
    const innerProps = getInnerProps({ ...fwdProps, hovered });

    return React.createElement(
      Component,
      { ...(innerProps as any), ...handlers }, // TODO: Try to avoid casting
      label || children,
    );
  };
}

export type LinkButtonProps = Props<typeof LinkButton>;

export const LinkButton = makeButtonWithComponent(Link);

export type ButtonProps = Props<typeof Button>;

export const Button = makeButtonWithComponent(PrimitiveButton);

export function SaveButton(props: ButtonProps) {
  return <Button {...props}>Save</Button>;
}

export function AddButton(props: ButtonProps) {
  // Setting props last, so that they can override the defaults set here.
  return (
    <Button onClick={prototypeWarning} theme="blue" {...props}>
      Add
    </Button>
  );
}

export function CreateButton(props: ButtonProps) {
  // Setting props last, so that they can override the defaults set here.
  return (
    <Button onClick={prototypeWarning} theme="blue" {...props}>
      Create
    </Button>
  );
}

export function CloseDialogButton(props: ButtonProps) {
  const dialogs = useDialogs();
  return (
    <Button {...props} theme="border" onClick={dialogs.closeDialog}>
      Cancel
    </Button>
  );
}
