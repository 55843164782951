import React from "react";
import { Button, CloseDialogButton } from "./button";
import { useDialogs } from "./dialog-manager";
import { useGlobalState } from "./state-manager";
import ActionsArea from "./actions-area";
import Dialog from "./dialog";
import Paragraph from "./paragraph";

interface ConfirmArchiveProjectDialog {
  projectId: string;
}

export default function ConfirmArchiveProjectDialog(props: ConfirmArchiveProjectDialog) {
  const globalState = useGlobalState();
  const dialogs = useDialogs();

  return (
    <Dialog title="Are you sure you want to archive this project?">
      <Paragraph>
        Archived projects are hidden from the dashboard. You can unarchive a
        project at any time.
      </Paragraph>
      <ActionsArea>
        <CloseDialogButton autoFocus />
        <Button
          label="Archive"
          theme="border"
          onClick={() => {
            globalState.toggleProjectArchival({ projectId: props.projectId });
            dialogs.closeDialog();
          }}
        />
      </ActionsArea>
    </Dialog>
  );
}
