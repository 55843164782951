import React, { ButtonHTMLAttributes } from "react";
import { useHover } from "./custom-hooks";
import Icon, { IconName, IconColor } from "./icon";
import PrimitiveButton from "./primitive-button";

export interface IconButtonProps
  extends ButtonHTMLAttributes<HTMLButtonElement> {
  icon: IconName;
  color?: IconColor;
}

export default function IconButton(props: IconButtonProps) {
  const { hovered, ...handlers } = useHover();
  const { icon, color, ...fwdProps } = props;

  return (
    <PrimitiveButton
      {...fwdProps}
      {...handlers}
      style={{ cursor: "pointer", position: "relative" }}>
      <div
        style={{
          position: "absolute",
          top: 0,
          right: 0,
          bottom: 0,
          left: 0,
          backgroundColor: "rgba(0,0,0,0.05)",
          borderRadius: "50%",
          opacity: hovered ? 1 : 0,
          transform: hovered ? "scale(1.4)" : "scale(1)",
          transition: "opacity 0.3s, transform 0.3s",
        }}
      />
      <Icon name={icon} color={color} />
    </PrimitiveButton>
  );
}
