import React, { ReactNode } from "react";
import { color } from "./stylesheet";
import { pick } from "./utils";

interface LabelProps {
  children: ReactNode;
  color?: "off-black" | "blue-gray";
  small?: boolean;
}

export default function Label(props: LabelProps) {
  return (
    <div
      style={{
        fontSize: props.small ? "1rem" : "1.25rem",
        lineHeight: props.small ? "1.25rem" : "1.5rem",
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
        color: pick(props.color || "off-black", {
          "blue-gray": color.BLUE_GRAY,
          "off-black": color.ALMOST_BLACK,
        }),
      }}>
      {props.children}
    </div>
  );
}
