import React, { ReactNode } from "react";
import { useViewportSize } from "./viewport-observer";
import View from "./view";

interface ContentViewProps {
  title: string;

  /** Top-level elements in `children` will be rendered as CSS Grid Items. */
  children: ReactNode;
}

export default function ContentView(props: ContentViewProps) {
  const viewport = useViewportSize();

  return (
    <View title={props.title}>
      <div
        style={{
          display: "grid",
          gap: viewport.isNarrowPlus ? "2rem" : "1rem",
          padding: viewport.isNarrowPlus ? "0" : "1rem",
        }}>
        {props.children}
      </div>
    </View>
  );
}
