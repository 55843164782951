import { isSafari } from "./utils";

export const color = {
  BLUE: "#0698ff",
  LIGHT_BLUE: "#e6f4ff",
  DARK_BLUE: "#0085e2",

  YELLOW: "#ffd700",

  ALMOST_BLACK: "#151f26",
  DARK_STEEL: "#485e6f",
  STEEL: "#5c7080",

  VERY_LIGHT_GRAY: "#fafafa",
  LIGHT_GRAY: "#f2f2f2",
  
  BLUE_GRAY: "#95a5b1",
  LIGHT_BLUE_GRAY: "#bac6d0",
};

export const zIndex = {
  STICKY_NAV: 1,
  DIALOG: 2,
}

export const height = {
  TOP_NAV: "4.5rem",
  TALL_TOP_NAV: "5.5rem",
}

export const position = {
  // Poor man's autoprefixer
  STICKY: isSafari ? "-webkit-sticky" : "sticky" as "sticky" | "-webkit-sticky",
}