import React, { useState } from "react";
import { color } from "./stylesheet";
import { prototypeWarning } from "./utils";
import { useToggle } from "./custom-hooks";
import { useViewportSize } from "./viewport-observer";
import BlankSlate from "./blank-slate";
import ExpandIndicator from "./expand-indicator";
import Grid, { GridGap } from "./grid";
import IconButton from "./icon-button";
import Tab from "./tab";
import Toolbar from "./toolbar";

export interface NamedItem {
  name: string;
}

export type FilterFn<I> = (item: I, query: string) => boolean;

export interface ManagedListState<I> {
  setFilter: (f: FilterFn<I>) => void;
  currentFilter: FilterFn<I>;
}

export interface ManagedListProps<Item extends NamedItem> {
  items: Item[];
  renderItem: (item: Item) => JSX.Element;
  columns: number;
  gap: GridGap;

  /**
   * Initial list filtering function.
   * @default function showAll() { return true; }
   */
  defaultFilter?: FilterFn<Item>;

  /**
   * Should the search interaction be enabled?
   * @default true
   */
  search?: boolean;

  /**
   * Blank Slate will be shown if there are no items provided to
   * the component.
   *
   * Note that this is different to when no items are
   * shown due to a filter.
   */
  renderBlankSlate?: (state: ManagedListState<Item>) => JSX.Element;

  /**
   * Actions will appear on the right side of the toolbar.
   */
  actions?: JSX.Element;

  /**
   * Tabs will appear on the left side of the toolbar, next to Search.
   * When Search is active, Tabs will be hidden.
   */
  tabs?: { label: string; filter: FilterFn<Item> }[];
}

interface FiltersProps {
  filters: { label: string; onClick: () => void; active: boolean }[];
}

function Filters(props: FiltersProps) {
  const viewport = useViewportSize();

  if (viewport.isNarrowPlus) {
    return (
      <div style={{ display: "flex" }}>
        {props.filters.map(filter => (
          <Tab key={filter.label} {...filter} />
        ))}
      </div>
    );
  }

  const activeFilter = props.filters.find(filter => filter.active);
  const label = activeFilter ? activeFilter.label : "Select filter";

  return (
    <div
      onClick={prototypeWarning}
      style={{
        display: "flex",
        fontSize: "1.25rem",
        lineHeight: "1.5rem",
        cursor: "pointer",
        color: color.BLUE_GRAY,
      }}>
      <div style={{ marginRight: "0.5rem" }}>{label}</div>
      <ExpandIndicator />
    </div>
  );
}

function showAll() {
  return true;
}

function filterByQuery(item: NamedItem, query: string) {
  return item.name.toLowerCase().includes(query.toLowerCase());
}

function defaultRenderBlankSlate() {
  return (
    <BlankSlate
      illustration="storyboards"
      title="Nothing here"
      instructions="Eventually, something might even appear..."
    />
  );
}

type UseFilterReturn<I> = [FilterFn<I>, (fn: FilterFn<I>) => void];

function useFilter<I>(defaultFilter: FilterFn<I>): UseFilterReturn<I> {
  // Note that storing functions in state is a bit awkward, because
  // React has functional overloads for both useState initial value 
  // (lazy initialization) and setState (functional setter). This means
  // that if a function is place into either of these functions, it will
  // automatically be called, which is not what we want.  Wrapping the
  // function in an object works around this issue.
  //
  // See the below links for more info:
  // https://reactjs.org/docs/hooks-reference.html#lazy-initialization
  // https://reactjs.org/docs/hooks-reference.html#functional-updates

  const [state, setState] = useState({ filter: defaultFilter });

  return [state.filter, (filter: FilterFn<I>) => setState({ filter })];
}

export default function ManagedList<Item extends NamedItem>(
  props: ManagedListProps<Item>,
) {
  const {
    defaultFilter = showAll,
    search = true,
    renderBlankSlate = defaultRenderBlankSlate,
  } = props;

  const [searchActive, toggleSearch] = useToggle();
  const [query, setQuery] = useState("");
  const [filter, setFilter] = useFilter(defaultFilter);

  function showSearchInput() {
    toggleSearch();
    setFilter(filterByQuery);
  }

  function hideSearchInput() {
    toggleSearch();
    setFilter(defaultFilter);
  }

  const state = {
    currentFilter: filter,
    setFilter,
  };

  if (props.items.length === 0) {
    // If this collection was empty to start off with we can
    // just short circuit the component here.
    return renderBlankSlate(state);
  }

  const collectionElements = props.items
    .filter(item => filter(item, query))
    .map(props.renderItem);

  return (
    <div>
      <Toolbar>
        {search && (
          <div style={{ marginRight: "1.5rem", display: "flex" }}>
            <IconButton
              icon="search"
              color={searchActive ? "blue" : "light-blue-gray"}
              onClick={
                filter === filterByQuery ? hideSearchInput : showSearchInput
              }
            />
          </div>
        )}

        {searchActive && (
          <input
            autoFocus
            value={query}
            onKeyUp={event => event.key === "Escape" && hideSearchInput()}
            onChange={event => setQuery(event.target.value)}
            placeholder="Search..."
            style={{
              lineHeight: "2rem",
              padding: "0.5rem 0",
              fontSize: "1.25rem",
              flex: "1 0 20rem,",
              backgroundColor: "transparent",
            }}
          />
        )}

        {!searchActive && props.tabs && (
          <Filters
            filters={props.tabs.map(tab => ({
              label: tab.label,
              onClick: () => setFilter(tab.filter),
              active: filter === tab.filter,
            }))}
          />
        )}

        {props.actions && (
          <div style={{ marginLeft: "auto" }}>{props.actions}</div>
        )}
      </Toolbar>

      {collectionElements.length === 0 ? (
        <BlankSlate
          title="No Items"
          instructions={
            searchActive
              ? "Try checking your spelling."
              : "Try switching to another filter."
          }
        />
      ) : (
        <Grid columns={props.columns} gap={props.gap}>
          {collectionElements}
        </Grid>
      )}
    </div>
  );
}
