import React from "react";
import { link } from "./paths";
import { LinkButton } from "./button";
import BlankSlate from "./blank-slate";

export default function UnrecognisedRouteView() {
  return (
    <BlankSlate
      illustration="users"
      title="Unrecognised Route"
      instructions="Try going back to where you came from, or jump to all projects."
      actions={<LinkButton to={link.toRootProjects()} label="Go to all projects"/>}
    />
  );
}
