import React from "react";
import { color } from "./stylesheet";
import { Link, Route, LinkProps } from "react-router-dom";
import { useHover } from "./custom-hooks";
import Icon, { IconName } from "./icon";

interface NavLinkProps extends LinkProps {
  icon: IconName | JSX.Element;
  label: string;
  condensed?: boolean;
  exact?: boolean;
}

export default function NavLink(props: NavLinkProps) {
  const { hovered, ...handlers } = useHover();
  const { icon, label, condensed, exact, ...fwdProps } = props;

  // The `to` prop can be a string or LocationDescriptorObject but `path` only
  // accepts a string. We need to guard againts this.
  const path = typeof props.to === "string" ? props.to : props.to.pathname;

  return (
    <Route path={path} exact={props.exact}>
      {route => (
        <Link
          {...fwdProps}
          {...handlers}
          style={{
            display: "flex",
            alignItems: "center",
            padding: "0.75rem",
            margin: "0 0.25rem",
            borderRadius: "0.25rem",
            color: route.match ? color.BLUE : color.DARK_STEEL,
          }}>
          <div
            style={{
              transform: hovered ? "scale(1.15)" : "scale(1)",
              transition: "transform 0.2s",
            }}>
            {typeof icon === "string" ? (
              <Icon
                name={icon}
                color={route.match ? "blue" : "light-blue-gray"}
              />
            ) : (
              icon
            )}
          </div>
          {!condensed && (
            <div
              style={{
                fontSize: "1.25rem",
                lineHeight: "1.5rem",
                marginLeft: "0.75rem",
              }}>
              {label}
            </div>
          )}
        </Link>
      )}
    </Route>
  );
}
