import React from "react";
import { Company } from "./entity-types";
import { link } from "./paths";
import { Redirect } from "react-router-dom";
import { useDialogs } from "./dialog-manager";
import { useGlobalState } from "./state-manager";
import CreateCompanyDialog from "./create-company-dialog";
import ListView from "./list-view";
import ResponsiveButton from "./responsive-button";
import ResponsiveListItem from "./responsive-list-item";

function composeDescription(company: Company) {
  return `${company.projects.length} projects / ${company.users.length} users`;
}

export default function RootCompaniesView() {
  const globalState = useGlobalState();
  const dialogs = useDialogs();

  const companies = globalState.getAllCompanies();

  function openDialog() {
    dialogs.openDialog(<CreateCompanyDialog />);
  }

  if (companies.length === 1) {
    // If there is only one company, just redirect to it...
    return <Redirect to={link.toCompanyRoot({ companyId: companies[0].id })} />;
  }

  return (
    <ListView
      title="Companies"
      items={companies}
      actions={
        <ResponsiveButton
          onClick={openDialog}
          label="Create Company"
          icon="add"
        />
      }
      renderItem={company => (
        <ResponsiveListItem
          key={company.id}
          to={link.toCompanyRoot({ companyId: company.id })}
          title={company.name}
          text={composeDescription(company)}
        />
      )}
    />
  );
}
