import React from "react";
import { CloseDialogButton, AddButton } from "./button";
import { prototypeWarning } from "./utils";
import ActionsArea from "./actions-area";
import Dialog from "./dialog";
import TextInput from "./text-input";

export default function AddUsersDialog() {
  return (
    <Dialog title="Add Users">
      <TextInput autoFocus label="Users" placeholder="Email address" />
      <ActionsArea>
        <CloseDialogButton />
        <AddButton theme="blue" onClick={prototypeWarning} />
      </ActionsArea>
    </Dialog>
  );
}
