import React, { InputHTMLAttributes } from "react";
import { color } from "./stylesheet";

interface TextInputProps extends InputHTMLAttributes<HTMLInputElement> {
  label: string;
}

export default function TextInput(props: TextInputProps) {
  const { label, ...fwdProps } = props;
  return (
    <label style={{ display: "block", marginBottom: "1.5rem" }}>
      <span
        style={{
          display: "block",
          color: color.DARK_STEEL,
          fontSize: "1.166666rem",
          lineHeight: "1.5rem",
        }}>
        {label}
      </span>
      <input
        {...fwdProps}
        style={{
          width: "100%",
          height: "3.5rem",
          fontSize: "1.166666rem",
          lineHeight: "1.5rem",
          color: color.ALMOST_BLACK,
          boxShadow: "inset 0 -1px 0 0 #e6e6e6",
        }}
      />
    </label>
  );
}
