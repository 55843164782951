import React from "react";
import { color } from "./stylesheet";
import { LinkCard } from "./card";
import { Project } from "./entity-types";

export default function ProjectCard(props: Project) {
  return (
    <LinkCard padding="small" to={`/projects/${props.id}`}>
      <div
        style={{
          display: "block",
          borderRadius: "0.25rem",
          backgroundColor: color.VERY_LIGHT_GRAY,
          backgroundImage: `url(${props.thumbUrl})`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          width: "100%",
          paddingBottom: "100%",
        }}
      />
      <div
        style={{
          fontSize: "1.33333rem",
          lineHeight: "2rem",
          color: color.ALMOST_BLACK,
          marginTop: "1rem",
          minHeight: "4rem",
        }}>
        {props.name}
      </div>
    </LinkCard>
  );
}
