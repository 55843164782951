import React from "react";
import { zIndex, position, height } from "./stylesheet";

interface TieredTopNavProps {
  children: [JSX.Element, JSX.Element];
}

export default function TieredTopNav(props: TieredTopNavProps) {
  const [header, links] = props.children;

  return (
    <nav
      style={{
        position: position.STICKY,
        top: 0,
        width: "100%",
        backgroundColor: "white",
        zIndex: zIndex.STICKY_NAV,
        boxShadow: "0 1px 3px -1px rgba(0,0,0,0.30)",
      }}>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          height: height.TOP_NAV,
          padding: "0 1.5rem",
        }}>
        {header}
      </div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          height: height.TOP_NAV,
          justifyContent: "space-evenly",
          boxShadow: "inset 0 1px 0 0 #e2e2e2",
        }}>
        {links}
      </div>
    </nav>
  );
}
