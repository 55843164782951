import React from "react";
import { CloseDialogButton, AddButton } from "./button";
import ActionsArea from "./actions-area";
import Dialog from "./dialog";
import TextInput from "./text-input";

export default function AddCollaboratorsDialog() {
  return (
    <Dialog title="Add Collaborators">
      <TextInput
        autoFocus
        label="Collaborators"
        placeholder="User, team, or email address"
      />
      <ActionsArea>
        <CloseDialogButton />
        <AddButton theme="blue" />
      </ActionsArea>
    </Dialog>
  );
}
