import React from "react";
import { CloseDialogButton, CreateButton } from "./button";
import ActionsArea from "./actions-area";
import Dialog from "./dialog";
import TextInput from "./text-input";

export default function CreateCompanyDialog() {
  return (
    <Dialog title="Create Company">
      <TextInput label="Name" placeholder="ACME Industries" />
      <TextInput label="Users" placeholder="User, team, or email address" />
      <ActionsArea>
        <CloseDialogButton />
        <CreateButton />
      </ActionsArea>
    </Dialog>
  );
}
