import React, { MouseEvent } from "react";
import { color } from "./stylesheet";
import { useHover } from "./custom-hooks";
import PrimitiveButton from "./primitive-button";

interface TabProps {
  label: string;
  active?: boolean;
  onClick?: (event: MouseEvent) => void;
}

export default function Tab(props: TabProps) {
  const { hovered, ...handlers } = useHover();
  const interactive = !!props.onClick;

  return (
    <PrimitiveButton
      {...handlers}
      onClick={props.onClick}
      style={{
        fontSize: "1.25rem",
        lineHeight: "2rem",
        padding: "0.5rem 0",
        marginRight: "1.5rem",
        boxShadow: props.active
          ? `inset 0 -2px 0 0 ${color.BLUE}`
          : interactive && hovered
          ? `inset 0 -2px 0 0 ${color.LIGHT_BLUE_GRAY}`
          : `inset 0 0px 0 0 ${color.LIGHT_BLUE_GRAY}`,
        cursor: interactive ? "pointer" : "initial",
        color: props.active ? color.BLUE : color.STEEL,
        transition: "box-shadow 0.2s",
      }}>
      {props.label}
    </PrimitiveButton>
  );
}
