import React from "react";
import { pick } from "./utils";

export type IllustrationName =
  | "users"
  | "storyboards"
  | "data-sources"
  | "teams"
  | "projects";

interface IllustrationProps {
  name: IllustrationName;
  size: "small" | "medium" | "large";
}

export default function Illustration(props: IllustrationProps) {

  // prettier-ignore
  const illustrationUrl = pick(props.name, {
    "users": "/images/users-illustration.svg",
    "teams": "/images/users-illustration.svg", // TODO: Replace with more fitting illustration
    "projects": "/images/storyboards-illustration.svg", // TODO: Replace with more fitting illustration
    "storyboards": "/images/storyboards-illustration.svg",
    "data-sources": "/images/data-sources-illustration.svg",
  });

  const size = pick(props.size, {
    small: "10rem",
    medium: "16rem",
    large: "22rem",
  });

  return (
    <div
      style={{
        width: size,
        height: size,
        backgroundImage: `url(${illustrationUrl})`,
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
      }}
    />
  );
}
