import React, { ReactNode } from "react";
import { color } from "./stylesheet";

interface DescriptiveTextProps {
  children: ReactNode;
}

export default function Paragraph(props: DescriptiveTextProps) {
  return (
    <div
      style={{
        fontSize: "1.25rem",
        lineHeight: "1.75rem",
        marginBottom: "1rem",
        color: color.STEEL,
      }}>
      {props.children}
    </div>
  );
}
