import React, { ReactNode } from "react";

interface GradientSlateProps {
  children: ReactNode;
}

export default function GradientSlate(props: GradientSlateProps) {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        borderRadius: "0.25rem",
        padding: "3.5rem",
        background: "linear-gradient(-180deg, #f2f2f2 4%, rgba(242,242,242,0.00) 97%)",
      }}>
      {props.children}
    </div>
  );
}
