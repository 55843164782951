import React, { ReactNode } from "react";
import { color } from "./stylesheet";

interface ListGroupLabelProps {
  children?: ReactNode;
}

export default function ListGroupLabel(props: ListGroupLabelProps) {
  return (
    <div
      style={{
        fontWeight: 700,
        fontSize: "1rem",
        lineHeight: "1.5rem",
        textTransform: "uppercase",
        color: color.BLUE_GRAY,
        margin: "3rem 0 1rem",
      }}>
      {props.children}
    </div>
  );
}
