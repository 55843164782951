import React from "react";
import { Button, ButtonProps } from "./button";
import { IconName, IconColor } from "./icon";
import { useViewportSize } from "./viewport-observer";
import IconButton from "./icon-button";

interface ResponsiveButtonProps extends ButtonProps {
  icon: IconName;
  color?: IconColor;
}

export default function ResponsiveButton(props: ResponsiveButtonProps) {
  const viewport = useViewportSize();
  const { icon, color, ...fwdProps } = props;

  if (!viewport.isNarrowPlus) {
    return <IconButton {...fwdProps} icon={icon} color={color} />;
  }

  return <Button {...fwdProps} />;
}
