import React from "react";
import { path, link } from "./paths";
import { RootTopNav, RootSideNav } from "./root-nav";
import { Switch, Redirect, Route } from "react-router-dom";
import RootCompaniesView from "./root-companies-view";
import RootProjectsView from "./root-projects-view";
import RootUserView from "./root-user-view";
import SidebarLayout from "./sidebar-layout";
import UnrecognisedRouteView from "./unrecognised-route-view";

export default function RootView() {
  return (
    <SidebarLayout
      renderTopNav={() => <RootTopNav />}
      renderSideNav={() => <RootSideNav />}>
      <Switch>
        <Redirect exact from={path.rootStoryboards} to={link.toRoot()} />
        <Route exact path={path.rootProjects} component={RootProjectsView} />
        <Route exact path={path.rootCompanies} component={RootCompaniesView} />
        <Route exact path={path.rootUser} component={RootUserView} />
        <Route component={UnrecognisedRouteView} />
      </Switch>
    </SidebarLayout>
  );
}
