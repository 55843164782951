import { RouteComponentProps, withRouter } from "react-router-dom";
import { useDialogs } from "./dialog-manager";
import { useEffect } from "react";

/**
 * Handles various global effects that need to be performend on route change.
 */
export default withRouter(function NavManager(props: RouteComponentProps) {
  // Close all currently opened dialogs
  const dialogs = useDialogs();
  useEffect(() => dialogs.closeAll(), [props.location.pathname]);

  // Restore scroll on route change (RR v4 doesn't do it  automatically).
  // See https://github.com/ReactTraining/react-router/blob/master/packages/react-router-dom/docs/guides/scroll-restoration.md
  useEffect(() => window.scrollTo(0, 0), [props.location.pathname]);

  // This is purely effectful component — don't render anything.
  return null;
});
