import React from "react";
import { BrowserRouter, Switch, Redirect, Route } from "react-router-dom";
import { path, link } from "./paths";
import StateManager from "./state-manager";
import CompanyView from "./company-view";
import DialogManager from "./dialog-manager";
import NavManager from "./nav-manager";
import ProjectView from "./project-view";
import RootView from "./root-view";
import StoryboardView from "./storyboard-view";
import ViewportObserver from "./viewport-observer";

export default function App() {
  return (
    <StateManager>
      <ViewportObserver>
        <BrowserRouter>
          <DialogManager>
            <NavManager />

            <Switch>
              <Redirect exact from={path.root} to={link.toRootProjects()} />
              <Route path={path.projectRoot} component={ProjectView} />
              <Route path={path.companyRoot} component={CompanyView} />
              <Route path={path.storyboardRoot} component={StoryboardView} />

              {/* Root view is a fallback that handles all routes within the
               "root" layout, including the unrecognised route handler. */}
              <Route component={RootView} />
            </Switch>
          </DialogManager>
        </BrowserRouter>
      </ViewportObserver>
    </StateManager>
  );
}
