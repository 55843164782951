import React, { ReactNode } from "react";
import { useViewportSize } from "./viewport-observer";
import { zIndex, position, height } from "./stylesheet";

interface TopNavProps {
  children: ReactNode;
}

export default function TopNav(props: TopNavProps) {
  const viewport = useViewportSize();

  return (
    <nav
      style={{
        position: position.STICKY,
        top: 0,
        width: "100%",
        backgroundColor: "white",
        zIndex: zIndex.STICKY_NAV,
        boxShadow: "0 1px 3px -1px rgba(0,0,0,0.30)",
        display: "flex",
        alignItems: "center",
        height: viewport.isMediumPlus ? height.TALL_TOP_NAV : height.TOP_NAV,
        padding: "0 1.5rem",
      }}>
      {props.children}
    </nav>
  );
}
