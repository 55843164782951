import React, { ReactNode } from "react";
import { position } from "./stylesheet";

interface SideNavProps {
  narrow?: boolean;
  children: ReactNode;
}

export default function SideNav(props: SideNavProps) {
  return (
    <nav
      style={{
        position: position.STICKY,
        height: "100vh",
        top: 0,
        display: "flex",
        flexDirection: "column",
        alignItems: props.narrow ? "center" : "stretch",
        backgroundColor: "white",
        boxShadow: "1px 0 0 0 #e6e6e6",
        flex: props.narrow ? "0 0 6rem" : "0 0 26rem",
        padding: "1rem",
        overflow: "auto",
      }}>
      {props.children}
    </nav>
  );
}
