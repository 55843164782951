import React from "react";
import BlankSlate, { BlankSlateProps } from "./blank-slate";

export default function NotFoundView(props: BlankSlateProps) {
  return (
    <div style={{ padding: "3rem" }}>
      <BlankSlate {...props} />
    </div>
  );
}
