import React from "react";
import { Button } from "./button";
import { link } from "./paths";
import { Project } from "./entity-types";
import { sortByStarred } from "./sorts";
import { useDialogs } from "./dialog-manager";
import AddStoryboardsDialog from "./add-storyboards-dialog";
import BlankSlate from "./blank-slate";
import GridView from "./grid-view";
import ResponsiveButton from "./responsive-button";
import ResponsiveListItem from "./responsive-list-item";
import StoryboardCard from "./storyboard-card";

export default function ProjectStoryboardsView(props: Project) {
  const dialogs = useDialogs();

  function openDialog() {
    dialogs.openDialog(<AddStoryboardsDialog projectId={props.id} />);
  }

  const sortedStoryboards = props.storyboards.sort(sortByStarred);

  return (
    <GridView
      title="Storyboards"
      items={sortedStoryboards}
      columns={4}
      actions={
        <ResponsiveButton
          onClick={openDialog}
          label="Add Storyboards"
          icon="add"
        />
      }
      renderGridItem={storyboard => (
        <StoryboardCard {...storyboard} key={storyboard.id} />
      )}
      renderListItem={storyboard => (
        <ResponsiveListItem
          key={storyboard.id}
          to={{
            pathname: link.toStoryboardRoot({ storyboardId: storyboard.id }),
            state: { returnTo: location.pathname },
          }}
          title={storyboard.name}
          text={storyboard.description}
        />
      )}
      renderBlankSlate={() => (
        <BlankSlate
          illustration="storyboards"
          title="No Storyboards"
          instructions="Select the storyboards that are just right for you and your team."
          actions={
            <Button onClick={openDialog} label="Add Storyboards" theme="blue" />
          }
        />
      )}
    />
  );
}
